import { LocalForageStore } from "features/LocalForage";

import { BookingFormLocalStateSchema } from "./model";

const CREATE_BOOKING_FORM_STORE_NAME = "create_booking_form_store";

export const CACHED_TIME = 2 * 60 * 60 * 1000;

export const bookingFormLocalStore = new LocalForageStore({
  name: CREATE_BOOKING_FORM_STORE_NAME,
  description: "Store booking data for CreateBookingForm",
  schema: BookingFormLocalStateSchema,
});
