import type { BookingTime } from "containers/CreateBookingForm/model";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { useFetchTimeSlotsQuery } from "features/api/slots";
import type { RestaurantId } from "models/restaurant.model";
import { TimeSlotDTO } from "models/slot.model";
import { Fragment } from "react";
import { type Control, useWatch } from "react-hook-form";
import type { Hour } from "types/commons";
import { RadioGroup, Spinner } from "ui-kit";

import type { GuestAndTimeFormSchemaInput } from "../model/schema";
import {
  deserializeShiftData,
  getHour,
  getMinutes,
  isTimeBetween,
} from "../utils";
import styles from "./TimeRadioGroup.module.scss";

dayjs.extend(isToday);

type TimeSlot = {
  value: BookingTime;
  label: string;
  slotCoveredIn: number;
  slotMaxCoveredIn: number;
  isStartOfHour: boolean;
};

export const TimeRadioGroup = ({
  name,
  control,
  restaurantId,
  bookingDate,
  defaultBookingTime,
  children,
  error,
}: {
  name: "bookingTime";
  control: Control<GuestAndTimeFormSchemaInput>;
  restaurantId: RestaurantId;
  bookingDate: string;
  defaultBookingTime: BookingTime | undefined;
  children: (timeSlot: TimeSlot) => JSX.Element;
  error?: string | string[];
}) => {
  const stringifiedShift = useWatch({
    control,
    name: "shift",
  });
  const duration = useWatch({ control, name: "visitDuration" });
  const persons = useWatch({ control, name: "persons" });
  const shift = deserializeShiftData(stringifiedShift);
  const isBookingDateToday = dayjs.tz(bookingDate).isToday();
  const currentTime = dayjs.tz().format("HH:mm:ss");

  const { data, isLoading } = useFetchTimeSlotsQuery({
    restaurantId: restaurantId,
    date: bookingDate,
    shiftId: shift.shiftId,
    visitDuration: duration,
    persons: Number(persons),
  });

  const startShiftTime = dayjs(shift.startDatetime);
  const startShiftTimeInMinutes =
    startShiftTime.minute() + startShiftTime.hour() * 60;
  const endShiftTime = dayjs(shift.endDatetime);
  // 1440 - если конец смены 00:00 следующего дня
  const endShiftTimeInMinutes =
    endShiftTime.minute() + endShiftTime.hour() * 60 || 1440;

  const { timeSlots, defaultValue } = (data || []).reduce<{
    timeSlots: TimeSlot[];
    defaultValue: BookingTime | undefined;
  }>(
    (acc, timeSlot) => {
      const timeInMinutes =
        getHour(timeSlot.time_short_name) * 60 +
        getMinutes(timeSlot.time_short_name);

      const isTimeInShiftRange = isTimeBetween(
        startShiftTimeInMinutes,
        timeInMinutes,
        endShiftTimeInMinutes,
      );
      if (
        (!isBookingDateToday || timeSlot.time_short_name >= currentTime) &&
        isTimeInShiftRange
      ) {
        const isStartOfHour =
          acc.timeSlots.length === 0 ||
          getHour(timeSlot.time_short_name) !==
            getHour(acc.timeSlots.at(-1)!.value);

        acc.timeSlots.push({
          value: timeSlot.time_short_name as BookingTime,
          label: timeSlot.time_short_name.slice(0, 5),
          slotCoveredIn: timeSlot.slot_covered_in,
          slotMaxCoveredIn: timeSlot.slot_max_covered_in,
          isStartOfHour,
        });

        if (timeSlot.time_short_name === defaultBookingTime)
          acc.defaultValue = defaultBookingTime;

        acc.defaultValue ||=
          defaultBookingTime &&
          isTimeBetween(
            timeInMinutes,
            getHour(defaultBookingTime) * 60 + getMinutes(defaultBookingTime),
            timeInMinutes + shift.timeInterval,
          ) &&
          (timeSlot.time_short_name as BookingTime);
      }
      return acc;
    },
    { timeSlots: new Array<TimeSlot>(), defaultValue: undefined },
  );

  return (
    <RadioGroup.Root
      className={styles.timeSlotsList}
      groupName={name}
      variant="flat"
      error={error}
      defaultValue={defaultValue || timeSlots?.[0]?.value}
    >
      {timeSlots ? (
        <RadioGroup.Content className={styles.timeSlots}>
          {timeSlots.map((timeSlot) => (
            <Fragment key={timeSlot.value}>
              {timeSlot.isStartOfHour && (
                <span className={styles.slotDivider}>
                  {`${getHour(timeSlot.value)}:00`}
                </span>
              )}
              {children(timeSlot)}
            </Fragment>
          ))}
        </RadioGroup.Content>
      ) : (
        <Spinner />
      )}
    </RadioGroup.Root>
  );
};
