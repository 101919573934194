import type { SlotId } from "../models/booking.model";
import type { UserUUID } from "../models/user.model";
import type { RecordType } from "./communication";
import { ETranslations } from "./translates";

export interface Response<T> {
  data: T;
  timestamp: string;
  // optionals
  errorCode?: number;
  errorMessage?: string;
}

export type ErrorResponse = {
  status: "ERROR";
  error?: {
    data?: { status?: number; errorCode?: number; errorMessage?: string };
    name?: string;
    message?: string;
  };
};

export type SortDirection = "ASC" | "DESC";
export type OrderType = "active" | "history";

export interface Pagination<T> {
  content: Array<T>;
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    pageNumber: number;
    pageSize: number;
    offset: number;
  };
  size: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  totalElements: number;
  totalPages: number;
}

export type TPluralsTitles = [
  ETranslations,
  ETranslations,
  ETranslations,
  ETranslations,
];
export type TNullable<T> = T | null | undefined;
export enum ELocales {
  ru_RU = "ru_RU",
  en_EN = "en_EN",
}

export type ShortLocales = "ru" | "en";

export type Locales = "ru_RU" | "en_EN";

export enum EDaysOfWeek {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

type GetEventHandlers<T extends keyof JSX.IntrinsicElements> = Extract<
  keyof JSX.IntrinsicElements[T],
  `on${string}`
>;

export type EventFor<
  TElement extends keyof JSX.IntrinsicElements,
  THandler extends GetEventHandlers<TElement>,
> = JSX.IntrinsicElements[TElement][THandler] extends
  | ((e: infer TEvent) => any)
  | undefined
  ? TEvent
  : never;

export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

declare const brand: unique symbol;
export type Brand<T, TBrand> = T & { [brand]: TBrand };

export type Params = {
  clientId: `${number}`;
  sourceId: `${number}`;
  dialogId: string;
  record: `${RecordType}-${number}`;
  slotId: `${SlotId}`;
  userUUID: UserUUID;
};

export type SimpleEntries<T extends {}> = {
  [K in keyof T]: [K extends number ? `${number}` : K, T[K]];
}[keyof T][];

export type NonEmptyArray<T> = [T, ...T[]] | [...T[], T] | [T, ...T[], T];

export type RequiredBy<T, U extends keyof T> = Prettify<
  Partial<Omit<T, U>> & {
    [key in U]-?: NonNullable<T[key]>;
  }
>;

export type IfEquals<T, S> = [T] extends [S]
  ? [S] extends [T]
    ? T
    : never
  : never;

type oneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type Digit = 0 | oneToNine;

export type Hour =
  | `0${Digit}`
  | `1${Digit}`
  | `2${Extract<Digit, 0 | 1 | 2 | 3>}`;

export type Minute = `${Extract<Digit, 0 | 1 | 2 | 3 | 4 | 5>}${Digit}`;
