import { Sentry } from "common/sentry";
import _ from "lodash";
import type { AuthRequest, GrantedUser, User } from "models/user.model";
import { tryCatch } from "utils";

import { config } from "../config";
import { ELocales, type Response } from "../types/commons";

interface AuthData {
  login: string;
  password: string;
  locale?: ELocales;
}

export type Role =
  | "ADMINISTRATOR"
  | "RESTAURANT"
  | "CALL_CENTER"
  | "ADMINISTRATOR_RESTAURANT"
  | "HOSTESS";

export type AccessTokenInfo = { token: string; expDateMs: number };

export interface IExternalAuthProvider {
  isAuthenticated(): boolean;
  needRefresh(): boolean;
  refresh(): Promise<unknown>;
  logout(): void;
  getAccessToken(): Promise<AccessTokenInfo>;
  reportForbidden(url: string): void;
}

type AuthResponse = {
  user: User;
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
  refresh_expires_in: number;
  session_state: string;
  scope: string;
  "not-before-policy": string;
};

type AuthRefresh = {
  access_token: string;
  refresh_token: string;
  expire_in: number;
  //
  token_type: string;
  session_id: string;
};

type TokensStorage = {
  access: string;
  refresh: string;
  //-
  accessExp: number;
  refreshExp: number;
};

export type AuthErrorCause = {
  code: number | undefined;
  message: string | undefined;
};

const STORAGE_TOKENS_KEY = "auth-tokens";

class AuthServiceIml {
  private parseJWT(token: string) {
    const parsed = JSON.parse(atob(token.split(".")[1]));
    return parsed;
  }

  private externalAuth: IExternalAuthProvider | null = null;

  /**
   * Делеировать работу с токенами внешнему провайдеру.
   *
   * Используется для получения токенов от нативного приложения в
   * разделах, заточеных на показ в WebView
   */
  useExternalAuth(auth: IExternalAuthProvider | null) {
    this.externalAuth = auth;
  }

  async getAccessToken(): Promise<AccessTokenInfo | null> {
    if (this.externalAuth) return this.externalAuth.getAccessToken();

    if (this.needRefresh()) {
      await this.refresh();
    }

    const tokens = this.getTokens();
    if (!tokens) return null;

    return { token: tokens.access, expDateMs: tokens.accessExp };
  }

  getTokens(): TokensStorage | null {
    const data = localStorage.getItem(STORAGE_TOKENS_KEY);
    if (!data) return null;
    try {
      return JSON.parse(data) as TokensStorage;
    } catch {
      this.deleteTokens();
      return null;
    }
  }

  setTokens(access: string, refresh: string): void {
    const tokens: TokensStorage = {
      access,
      refresh,
      //-
      accessExp: this.parseJWT(access).exp * 1000,
      refreshExp: this.parseJWT(refresh).exp * 1000,
    };

    localStorage.setItem(STORAGE_TOKENS_KEY, JSON.stringify(tokens));
  }

  private deleteTokens(): void {
    localStorage.removeItem(STORAGE_TOKENS_KEY);
  }

  isAuthenticated(): boolean {
    if (this.externalAuth) return this.externalAuth.isAuthenticated();

    const tokens = this.getTokens();

    if (!tokens) return false;

    const now = Date.now();

    return now <= tokens.accessExp || now <= tokens.refreshExp;
  }

  private needRefresh() {
    if (this.externalAuth) return this.externalAuth.needRefresh();

    const tokens = this.getTokens();

    if (!tokens) return false;

    const now = Date.now();

    return now >= tokens.accessExp && now <= tokens.refreshExp;
  }

  private setUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  // TODO переписать на  Redux
  /** @deprecated Пользователя нужно хранить в store */
  getUser() {
    let user;
    if (localStorage.getItem("user")) {
      user = tryCatch(
        () => JSON.parse(localStorage.getItem("user") || ""),
        () => null,
      ) as User | null;
    } else {
      // Not broken previous auth logic
      const name = localStorage.getItem("nameUser") ?? "";
      const photo = localStorage.getItem("photoUser") ?? "";
      const role = (localStorage.getItem("role") as Role) ?? "HOSTESS";
      user = { name, photo, role };
    }
    Sentry.setUser(user);
    return user;
  }

  async login(data: AuthRequest) {
    const resp: Response<AuthResponse> = await fetch(`api/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((r) => r.json());

    if (!resp.data)
      throw new Error("Unable to login", {
        cause: {
          code: resp.errorCode,
          message: resp.errorMessage,
        } satisfies AuthErrorCause,
      });

    const { access_token, refresh_token, user } = resp.data;
    this.setTokens(access_token, refresh_token);
    return user;
  }

  private refreshPromise: Promise<unknown> | undefined;

  async refresh() {
    if (this.externalAuth) return this.externalAuth.refresh();

    if (this.refreshPromise) return this.refreshPromise;

    const promise = (async () => {
      const tokens = this.getTokens();

      if (!tokens || !this.isAuthenticated()) {
        this.logout();
        throw new Error("Error! AuthService: no token to refresh");
      }

      const res = await fetch(`/api/auth/refresh`, {
        method: `POST`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: tokens.refresh }),
      });

      if ([401, 403].includes(res.status)) {
        this.logout();
        throw new Error("Error! AuthService: refresh token in not valid");
      }

      if (res.status !== 200) {
        this.logout();
        throw new Error("Error! AuthService: error while refreshing tokens");
      }

      const {
        data: { access_token, refresh_token },
      } = (await res.json()) as Response<AuthRefresh>;

      this.setTokens(access_token, refresh_token);
    })();

    promise.finally(() => {
      if (this.refreshPromise === promise) this.refreshPromise = undefined;
    });

    this.refreshPromise = promise;
    return promise;
  }

  async fetchWithAuthentication(
    req: Request,
    isRetry = false,
  ): Promise<globalThis.Response> {
    if (isRetry) {
      await this.refresh();
    }

    const access = await this.getAccessToken();
    if (!access || !this.isAuthenticated()) {
      this.logout();
      throw new Error("Unauthorized request: no tokens or tokens expired");
    }

    req.headers.set("Authorization", access.token);

    const resp = await global.fetch(req);

    if (resp.status === 401) {
      if (!isRetry) {
        return this.fetchWithAuthentication(req.clone(), true);
      }

      this.logout();
      return resp;
    }

    if (this.externalAuth && resp.status === 403) {
      this.externalAuth.reportForbidden(req.url);
    }

    return resp;
  }

  logout() {
    this.deleteTokens();

    if (this.externalAuth) this.externalAuth.logout();

    // TODO: удалить эту строчку где-нибудь в 2025 :)
    localStorage.removeItem("auth-token-wrf");
    try {
      _.get(global, "channels.auth.cb")?.(false);
    } catch (e) {
      console.warn("Logout error");
    }
  }

  /**
   * Создать новый экземпляр AuthService, обычно для использования в
   * IExternalAuthProvider или для отладки
   *
   * */
  new() {
    return new AuthServiceIml();
  }
}

export const AuthService = new AuthServiceIml();
