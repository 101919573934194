import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "components/ConfirmModal";
import { FormClose } from "components/MultiStepForm";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import {
  Button,
  LinkButton,
  ModalDialog,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogForm,
  ModalDialogTrigger,
} from "ui-kit";

export const CrateBookingFormCloseButton = () => {
  const { formatMessage } = useIntl();
  return (
    <ModalDialog>
      <ModalDialogTrigger as={FormClose} />
      <ModalDialogContent>
        <ModalDialogCloseButton />
        <ModalDialogForm>
          <ConfirmMessage>
            <ConfirmMessageHeader>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_HEADER,
              })}
            </ConfirmMessageHeader>
            <ConfirmMessageText>
              {formatMessage({
                id: ETranslations.CLOSE_BOOKING_TEXT,
              })}
            </ConfirmMessageText>
            <ConfirmMessageActions>
              <LinkButton variant="dangerous" to="/dashboard">
                {formatMessage({
                  id: ETranslations.CLOSE_BOOKING_CONFIRM,
                })}
              </LinkButton>
              <ModalDialogCloseButton isCancelButton variant="secondary">
                {formatMessage({ id: ETranslations.BASE_CANCEL })}
              </ModalDialogCloseButton>
            </ConfirmMessageActions>
          </ConfirmMessage>
        </ModalDialogForm>
      </ModalDialogContent>
    </ModalDialog>
  );
};
