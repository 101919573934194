import cn from "classnames";
import { FormFooter, StepContainer } from "components/MultiStepForm";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type { StepNumber } from "containers/CreateBookingForm/model";
import { ClientList } from "containers/Guests/Clients/ClientList";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

import { ClientForm } from "./ClientForm";
import styles from "./ClientInfoStep.module.scss";
import { useClientInfoStepState } from "./useClientIfoStepState";

export const STEP_NUMBER: StepNumber = 3;
const CREATE_CLIENT_FORM_ID = "CREATE_CLIENT_FORM_ID";

export const ClientInfoStep = () => {
  const { formatMessage } = useIntl();
  const {
    bookingData,
    updateBookingData,
    updateStepValidationState,
    findNextInvalidStep,
  } = useCreateBookingFormContext();
  const navigate = useNavigate();

  const nextInvalidStep = findNextInvalidStep(STEP_NUMBER);
  const nextOrLastStep = `/create-booking-new/step-${nextInvalidStep || 5}`;
  const {
    isClientListOpen,
    isClientFormValid,
    clientFormType,
    openClientForm,
    closeClientForm,
    changeClientFormValidation,
  } = useClientInfoStepState();

  return (
    <>
      <StepContainer
        className={cn(
          styles.clientInfoStep,
          isClientListOpen ? styles.showClientList : styles.showClientForm,
        )}
      >
        <ClientList
          className={styles.clientList}
          withoutTagsSelect
          addon={
            <menu className={styles.showFormButtons}>
              <li>
                <Button
                  className={styles.showClientFormButton}
                  variant="thin"
                  onClick={() => openClientForm("CONTACT")}
                >
                  {formatMessage({ id: ETranslations.REGISTER_CONTACT })}
                </Button>
              </li>
              <li>
                <Button
                  className={styles.showClientFormButton}
                  variant="thin"
                  onClick={() => openClientForm("CLIENT")}
                >
                  {formatMessage({ id: ETranslations.ADD_NEW_CLIENT })}
                </Button>
              </li>
            </menu>
          }
          onSelectClient={(client) => {
            updateBookingData({
              client: {
                clientId: client.client_id,
                phone: client.phone,
                surname: client.surname || "",
                name: client.name || "",
                middleName: client.middle_name || "",
                contactType: "CLIENT",
              },
            });
            updateStepValidationState({
              step3: true,
            });
            navigate(nextOrLastStep);
          }}
          selectedClientId={bookingData.client?.clientId}
        />
        <ClientForm
          formId={CREATE_CLIENT_FORM_ID}
          clientFormType={clientFormType}
          setIsValid={changeClientFormValidation}
          onSubmit={(client) => {
            updateBookingData({
              client,
            });
            updateStepValidationState({
              step3: true,
            });
            navigate(nextOrLastStep);
          }}
        >
          <Button
            variant="phantom"
            className={styles.backButton}
            onClick={closeClientForm}
          >
            {formatMessage({ id: ETranslations.BACK_TO_SEARCH })}
          </Button>
        </ClientForm>
      </StepContainer>
      {isClientListOpen
        ? bookingData.client && (
            <CreateBookingFormFooter
              onBeforeNavigate={() => {
                updateStepValidationState({
                  step3: true,
                });
                return { success: true, data: bookingData };
              }}
              currentStep={STEP_NUMBER}
            />
          )
        : isClientFormValid && (
            <FormFooter>
              <Button
                variant="primary"
                type="submit"
                form={CREATE_CLIENT_FORM_ID}
              >
                {formatMessage({ id: ETranslations.CONTINUE })}
              </Button>
            </FormFooter>
          )}
    </>
  );
};
