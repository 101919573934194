import { FormStepper, type StepInfo } from "components/MultiStepForm";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

import { useCreateBookingFormContext } from "../../Provider";
import { formatClient, formatTables, formatTimeAndPersons } from "../../utils";

export const CreateBookingFormStepper = () => {
  const { formatMessage } = useIntl();
  const { bookingData, restaurant, stepValidationState } =
    useCreateBookingFormContext();
  const stepsInfo: StepInfo[] = [
    {
      title: formatMessage({ id: ETranslations.RESERVATION_DATE_SELECT }),
      description: dayjs(bookingData.bookingDate).format("DD MMMM, dddd"),
      valid: stepValidationState.step1,
    },
    {
      title: formatMessage({ id: ETranslations.NUMBER_OF_GUESTS_TITLE }),
      description:
        bookingData.persons && bookingData.bookingTime
          ? formatTimeAndPersons({
              time: bookingData.bookingTime,
              persons: bookingData.persons,
              formatMessage,
            })
          : formatMessage({
              id: ETranslations.NUMBER_OF_GUESTS_DESC,
            }),
      valid: stepValidationState.step2,
    },
    {
      title: formatMessage({ id: ETranslations.PLURAL_GUEST }),
      description: bookingData.client
        ? formatClient(bookingData.client)
        : formatMessage({ id: ETranslations.GUEST_INFORMATION }),
      valid: stepValidationState.step3,
    },
    {
      title: formatMessage({ id: ETranslations.PLURAL_TABLE }),
      description: bookingData.tables
        ? formatTables({
            tables: bookingData.tables,
            placeById: restaurant.placeById,
            formatMessage,
          })
        : formatMessage({ id: ETranslations.SELECT_TABLE }),
      valid: stepValidationState.step4,
    },
    {
      title: formatMessage({ id: ETranslations.OTHER }),
      description: formatMessage({ id: ETranslations.ADDITIONAL_INFO }),
      valid: stepValidationState.step5,
    },
  ];
  return (
    <FormStepper
      stepsInfo={stepsInfo}
      errorText={formatMessage({ id: ETranslations.ERROR_FORM_PARAMETERS })}
    />
  );
};
