import { config } from "config";
import { useIntlUtils } from "hooks/useIntlUtils";
import type { Client } from "models/client.model";
import React, { FC } from "react";
import { ETranslations } from "types/translates";
import { capitalizeString } from "utils";

import styles from "./GuestInfoRow.module.scss";

interface TProps {
  guest: Client;
}

const GuestInfoRow: FC<TProps> = ({ guest }) => {
  const { intl } = useIntlUtils();

  return (
    <div className={styles.main}>
      <div className={styles.item}>
        <span className={styles.title}>
          {capitalizeString(
            intl.formatMessage({ id: ETranslations.PLURAL_VISITS_NOM }),
          )}
        </span>
        <span className={styles.description}>
          {guest.visit_count ??
            intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.CANCELS })}
        </span>
        <span className={styles.description}>
          {guest.cancel_count ??
            intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: ETranslations.DID_NOT_COME,
          })}
        </span>
        <span className={styles.description}>
          {guest.not_come_count ??
            intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.GUEST_TOTAL_SPEND })}
        </span>
        <span
          className={styles.description}
        >{`${Number(guest.total_purchase)} ${config.currency}`}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.GUEST_AVG_PER_VISIT })}
        </span>
        <span
          className={styles.description}
        >{`${Number(guest.avg_transaction)} ${config.currency}`}</span>
      </div>
    </div>
  );
};

export default GuestInfoRow;
