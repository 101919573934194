import { Modal } from "components/modal";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";

import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "./ConfirmMessage";

export const ConfirmModal = ({
  isOpen,
  header,
  children,
  confirm,
  close,
}: {
  isOpen: boolean;
  header?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
  confirm: () => Promise<void>;
  close: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Modal.Content as={ConfirmMessage}>
        <ConfirmMessageHeader>{header}</ConfirmMessageHeader>
        <ConfirmMessageText>{children}</ConfirmMessageText>
        <ConfirmMessageActions>
          <Button variant="dangerous" onClick={() => confirm().then(close)}>
            {formatMessage({ id: ETranslations.BASE_CONFIRM })}
          </Button>
          <Button variant="secondary" onClick={close}>
            {formatMessage({ id: ETranslations.BASE_CANCEL })}
          </Button>
        </ConfirmMessageActions>
      </Modal.Content>
    </Modal>
  );
};
