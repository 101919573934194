import { zodResolver } from "@hookform/resolvers/zod";
import type { ContactInfo } from "containers/CreateBookingForm/model";
import { useEffect } from "react";
import {
  type Control,
  useController,
  useForm,
  useWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { FieldError, Input } from "ui-kit";
import { commonFormErrorMap } from "utils";

import { MAX_NAME_LENGTH } from "../constants";
import { RegisterContactFormSchema } from "../model/schema";
import styles from "./ClientForm.module.scss";

const ControlledNameInput = ({
  control,
}: {
  control: Control<RegisterContactFormSchema>;
}) => {
  const { formatMessage } = useIntl();
  const { field, fieldState } = useController({
    control,
    name: "name",
    defaultValue: "",
  });
  const middleName = useWatch({ control, name: "middleName" });
  const surname = useWatch({ control, name: "surname" });
  const isMiddleNameAndSurnameEmpty = !(middleName || surname);
  return (
    <Input
      label={formatMessage({ id: ETranslations.NAME })}
      inputClassName={
        isMiddleNameAndSurnameEmpty ? styles.placeholderMatch : undefined
      }
      autoComplete="off"
      max={MAX_NAME_LENGTH}
      placeholder={
        isMiddleNameAndSurnameEmpty
          ? formatMessage({ id: ETranslations.PLURAL_GUEST })
          : undefined
      }
      {...field}
      error={fieldState.error?.message}
    />
  );
};

export const RegisterContactForm = ({
  formId,
  setIsValid,
  onSubmit,
}: {
  formId?: string;
  setIsValid: (isValid: boolean) => void;
  onSubmit: (contact: ContactInfo) => void;
}) => {
  const { formatMessage } = useIntl();
  const {
    control,
    formState: { errors, isValid, isDirty },
    register,
    handleSubmit,
  } = useForm<RegisterContactFormSchema>({
    resolver: zodResolver(RegisterContactFormSchema, {
      errorMap: commonFormErrorMap,
    }),
    mode: "onBlur",
  });
  useEffect(() => {
    setIsValid(isValid || !isDirty);
  }, []);
  return (
    <form
      id={formId}
      className={styles.clientForm}
      onChange={() => {
        setIsValid(isValid);
      }}
      onBlur={() => {
        setIsValid(isValid);
      }}
      onSubmit={handleSubmit(({ name, middleName, surname }) => {
        const isNameFieldsEmpty = !(name || middleName || surname);
        return onSubmit({
          name: isNameFieldsEmpty
            ? formatMessage({ id: ETranslations.PLURAL_GUEST })
            : name,
          middleName,
          surname,
          contactType: "CONTACT",
        });
      })}
    >
      <ControlledNameInput control={control} />
      <Input
        label={formatMessage({ id: ETranslations.LAST_NAME })}
        placeholder={formatMessage(
          { id: ETranslations.BASE_ENTER_VALUE },
          { value: formatMessage({ id: ETranslations.LAST_NAME_ALT }) },
        )}
        autoComplete="off"
        max={MAX_NAME_LENGTH}
        {...register("surname", { required: false })}
        error={errors.surname?.message}
      />

      <Input
        label={formatMessage({ id: ETranslations.MIDDLE_NAME })}
        autoComplete="off"
        max={MAX_NAME_LENGTH}
        {...register("middleName", { required: false })}
        error={errors.middleName?.message}
      />
      {errors.root?.message && (
        <FieldError className={styles.formError} error={errors.root.message} />
      )}
    </form>
  );
};
