import cn from "classnames";
import type { ReactNode } from "react";
import { Attention, Danger } from "ui-kit/ICONS/icons";

import styles from "./ConfirmMessage.module.scss";

export const ConfirmMessage = ({
  variant,
  className,
  iconClassName,
  children,
}: {
  variant?: "danger" | "warning" | "neutral";
  className?: string;
  iconClassName?: string;
  children: ReactNode;
}) => {
  const Icon = !variant || variant === "danger" ? Danger : Attention;
  return (
    <section className={cn(styles.confirmMessage, className)}>
      <Icon
        className={cn(styles.icon, styles[variant || "danger"], iconClassName)}
      />

      {children}
    </section>
  );
};

export const ConfirmMessageHeader = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <h2 className={cn(styles.header, className)}>{children}</h2>;

export const ConfirmMessageText = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <p className={cn(styles.message, className)}>{children}</p>;

export const ConfirmMessageActions = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => <div className={cn(styles.actions, className)}>{children}</div>;
