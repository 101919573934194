import { PluginHost, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import { BookingTable } from "components/HallEditor/BookingTable";
import { TablePath } from "components/HallEditor/TablePath";
import { placeSelector } from "features/AppContext";
import { HallSlotsDTO, useHallScheme } from "features/api/hallschema-api";
import { useTableFigures } from "features/api/tables-api";
import type { TableId } from "models/booking.model";
import { ReactNode, memo } from "react";
import { useSelector } from "react-redux";
import type { BookingTable as TBookingTable } from "types/table";
import { Button, ICONS, Spinner } from "ui-kit";

import { ShiftsTimeline } from "../../ShiftsTimeline/ShiftsTimeline";
import { HallMoveStatusControls } from "../HallMoveStatusControls";
import { HallLegend } from "../hall-legend";
import { HallControlsPlugin } from "./HallControlsPlugin/HallControlsPlugin";
import styles from "./NewScheme.module.scss";
import { PreserveScalePlugin } from "./PreserveScalePlugin";
import { NewSchemeContext, type Table } from "./context";
import type { PlaceId } from "models/restaurant.model";

type NewSchemeProps = {
  onBookingListClick?: () => void;
  hallInfo: HallSlotsDTO[];

  timelineToolbar?: ReactNode;
  customPlaceId?: number;
  selectedTableIds?: Set<TableId>;
  onSelectTable?: (table: Table) => void;
  onDeselectTable?: (table: Table) => void;
};

export const NewScheme = memo(
  ({
    hallInfo,
    timelineToolbar,
    customPlaceId,
    selectedTableIds,
    onSelectTable,
    onDeselectTable,
    onBookingListClick,
  }: NewSchemeProps) => {
    const appPlaceId = useSelector(placeSelector);
    const placeId = (customPlaceId ?? appPlaceId) as PlaceId;
    const scheme = useHallScheme({ placeId });
    const { data: figures, defaultFigure } = useTableFigures();

    if (!scheme || scheme.isFetching || !figures) {
      return <Spinner />;
    }

    const sectors = scheme.sectors.reduce<TBookingTable[][]>(
      (result, sector) => (
        sector.places &&
          result.push(
            sector.places.map<TBookingTable>(
              ({
                hostesInfo: { id, number, min_capacity, max_capacity },
                seat,
                figure_id,
                position,
              }) => {
                const tableFigure =
                  figures.find(
                    (figure) => Number(figure.figure_id) === figure_id,
                  ) || defaultFigure;
                return {
                  tableId: id,
                  tableNumber: seat || number,
                  tableCapacity: `${Math.max(min_capacity, 1)}-${Math.max(max_capacity, min_capacity, 1)}`,
                  position: position,
                  figure: {
                    figureId: figure_id,
                    width: tableFigure.width,
                    height: tableFigure.height,
                    shape: tableFigure.shape,
                  },
                };
              },
            ),
          ),
        result
      ),
      Array(),
    );

    return (
      <NewSchemeContext.Provider
        value={{
          placeId,
          selectedTableIds,
          onSelectTable,
          onDeselectTable,
        }}
      >
        <div className={styles.container}>
          <div className={styles.scheme}>
            <PluginHost>
              <Plugins.Core data={scheme}>
                <TemplatePlaceholder
                  name={Plugins.HallContent.PLUGIN_TEMPLATES.root}
                />
              </Plugins.Core>
              <Plugins.HallContent />
              <Plugins.Figure />
              <Plugins.Place />
              <Plugins.Sector />
              <Plugins.Zoom scaleFactor={0.1} />
              <Plugins.SelectedPlace />
              <Plugins.SelectedFigures />
              <Plugins.FigureActions />
              <TablePath hallInfo={hallInfo} />
              <BookingTable hallInfo={hallInfo} sectors={sectors} />
              <PreserveScalePlugin padding={20} />
              <HallControlsPlugin />
            </PluginHost>
            {onBookingListClick && (
              <Button
                className={styles.bookingListButton}
                variant="secondary"
                square
                onClick={onBookingListClick}
              >
                <ICONS.Book />
              </Button>
            )}
            <HallLegend className={styles.legend} />
          </div>
          <HallMoveStatusControls />
          {timelineToolbar}
          <ShiftsTimeline />
        </div>
      </NewSchemeContext.Provider>
    );
  },
);
