import cn from "classnames";
import { APP_NAME } from "../../constants";
import localforage from "localforage";
import { useIntl } from "react-intl";
import { Form, useAsyncError, useRouteError } from "react-router-dom";
import type { ErrorResponse } from "types/commons";
import { ETranslations } from "types/translates";
import { Button, ICONS } from "ui-kit";

import styles from "./ErrorBoundary.module.scss";

export const DefaultErrorBoundary = ({
  message,
  children,
  entity,
  className,
}: {
  message?: unknown;
  children: ETranslations;
  entity?: ETranslations;
  className?: string;
}) => {
  const routeError = useRouteError();
  const errorFromAwait = useAsyncError();
  const unknownError = message || routeError || errorFromAwait;

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(
          (unknownError as ErrorResponse["error"])?.data?.errorMessage ||
            unknownError,
        );
  const { formatMessage } = useIntl();
  return (
    <section className={cn(styles.noDetails, className)}>
      <ICONS.ErrorWarning width={80} height={80} />
      <p>
        {formatMessage(
          { id: children },
          {
            entity: entity && formatMessage({ id: entity }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      </p>
      <Form method="POST" action="/logout">
        <Button
          type="submit"
          variant="dangerous"
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            localforage.dropInstance({ name: APP_NAME });
          }}
        >
          {formatMessage({ id: ETranslations.HARD_REFRESH })}
        </Button>
      </Form>
    </section>
  );
};
