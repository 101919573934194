import { DefaultErrorBoundary } from "components/ErrorBoundary";
import { ETranslations } from "types/translates";



import styles from "./CreateBookingFormErrorBoundary.module.scss";


export const CreateBookingFormErrorBoundary = () => (
  <DefaultErrorBoundary
    className={styles.errorSection}
  >
    {ETranslations.ERROR_FORM_RESERVATION}
  </DefaultErrorBoundary>
);