import type {
  ShiftData,
  ShiftDetails,
} from "containers/CreateBookingForm/model";
import dayjs, { type Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useFetchShiftSlotsQuery } from "features/api/slots";
import type { RestaurantId } from "models/restaurant.model";
import type { ShiftSlotDTO } from "models/slot.model";
import { ETranslations } from "types/translates";
import { invariant } from "utils";

import { SINGLE_SHIFT_SLOTS_COUNT } from "../../constants";

dayjs.extend(isBetween);

type FormattedStringDateTime = "YYYY-MM-DDTHH:mm:ss" | (string & {});

export const formatToDateTime = (date: Dayjs): FormattedStringDateTime =>
  date.format("YYYY-MM-DDTHH:mm:ss");

const getDefaultValue = (
  now: FormattedStringDateTime,
  ranges: ShiftData[],
): ShiftData => {
  const defaultRangeIndex = ranges.findIndex(
    // сравниваем строки, поэтому важно одинаковое форматирование YYYY-MM-DDTHH:MM
    ({ startDatetime, endDatetime }) =>
      now >= startDatetime && now < endDatetime,
  );
  // Если defaultRangeIndex = - 1, то дефолтная кнопка будет с индексом 0, иначе используем defaultRangeIndex
  return ranges[~defaultRangeIndex && defaultRangeIndex];
};

const getSingleShiftDetails = ({
  shiftId,
  shiftName,
  startDatetime,
  endDatetime,
  shiftSlotsCount,
  currentDate,
  timeInterval,
}: ShiftData & {
  shiftSlotsCount: number;
  currentDate: FormattedStringDateTime;
}): {
  details: ShiftData[];
  defaultValue: ShiftData;
} => {
  const startShift = dayjs.tz(startDatetime);

  const endShift = dayjs.tz(endDatetime);
  const shiftDurationMinutes = endShift.diff(startShift, "minutes");

  if (shiftDurationMinutes < (shiftSlotsCount + 1) * 60) {
    return {
      details: [
        { shiftId, shiftName, startDatetime, endDatetime, timeInterval },
      ],
      defaultValue: {
        shiftId,
        shiftName,
        startDatetime,
        endDatetime,
        timeInterval,
      },
    };
  }

  const hourPerSLot = Math.round(
    shiftDurationMinutes / ((shiftSlotsCount + 1) * 60),
  );

  const slotRanges: ShiftData[] = Array.from(
    { length: shiftSlotsCount },
    (_, k) => ({
      shiftId,
      shiftName,
      startDatetime: formatToDateTime(startShift.add(k * hourPerSLot, "hours")),
      endDatetime: formatToDateTime(
        k + 1 === shiftSlotsCount
          ? endShift
          : startShift.add((k + 1) * hourPerSLot, "hours"),
      ),
      timeInterval,
    }),
  );

  const defaultValue = getDefaultValue(currentDate, slotRanges);

  return { details: slotRanges, defaultValue };
};

const getMultiShiftDetails = (
  shifts: ShiftSlotDTO[],
  currentDate: FormattedStringDateTime,
) =>
  shifts.reduce<{
    details: ShiftData[];
    defaultValue: ShiftData;
  }>(
    (acc, shift) => {
      const shiftData = {
        shiftId: shift.shift_id,
        shiftName: shift.shift_name,
        startDatetime: shift.start_datetime,
        endDatetime: shift.end_datetime,
        timeInterval: shift.time_interval,
      };
      acc.details.push(shiftData);
      if (
        currentDate > shiftData.startDatetime &&
        currentDate < shiftData.endDatetime
      )
        acc.defaultValue = shiftData;

      return acc;
    },
    {
      details: new Array<ShiftData>(),
      defaultValue: {
        shiftId: shifts[0].shift_id,
        shiftName: shifts[0].shift_name,
        startDatetime: shifts[0].start_datetime,
        endDatetime: shifts[0].end_datetime,
        timeInterval: shifts[0].time_interval,
      },
    },
  );

export const getShiftDetails = (
  shifts: ShiftSlotDTO[],
  currentDate: FormattedStringDateTime,
) => {
  invariant(shifts.length, ETranslations.RESTAURANT_SHIFT_REQUIRED, true);

  const type: ShiftDetails["type"] = shifts.length > 1 ? "multi" : "single";

  const { details, defaultValue } =
    type === "multi"
      ? getMultiShiftDetails(shifts, currentDate)
      : getSingleShiftDetails({
          shiftId: shifts[0].shift_id,
          shiftName: shifts[0].shift_name,
          startDatetime: shifts[0].start_datetime,
          endDatetime: shifts[0].end_datetime,
          shiftSlotsCount: SINGLE_SHIFT_SLOTS_COUNT,
          currentDate,
          timeInterval: shifts[0].time_interval,
        });
  return { type, defaultValue, details };
};

export const useShiftDetails = ({
  restaurantId,
  date,
  time,
}: {
  restaurantId: RestaurantId;
  date: string;
  time: string;
}) => {
  const { data, error, isLoading } = useFetchShiftSlotsQuery({
    restaurantId,
    date,
  });
  if (error) {
    throw new Error(JSON.stringify(error));
  }
  return {
    shiftDetails: data && getShiftDetails(data, `${date}T${time}`),
    isLoading,
  };
};
