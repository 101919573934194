import { zodResolver } from "@hookform/resolvers/zod";
import { StepContainer } from "components/MultiStepForm";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type {
  BookingData,
  StepNumber,
  TableDetails,
} from "containers/CreateBookingForm/model";
import { formatTimeAndPersons } from "containers/CreateBookingForm/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import { Button, ICONS, IconWithCaption, RadioGroup, Spinner } from "ui-kit";
import { commonFormErrorMap } from "utils";

import { TableFormSchema, type TableFormSchemaInput } from "../model/schema";
import { serializeTableData } from "../utils";
import { HallSelect } from "./HallSelect";
import { TableModal } from "./TableModal/TableModal";
import { TableRadioGroup } from "./TableRadioGroup";
import styles from "./TableStep.module.scss";

const STEP_NUMBER: StepNumber = 4;

export const TableStep = () => {
  const [isHallModalOpen, setIsHallModalOpen] = useState(false);
  const handleHallModalToggle = () => setIsHallModalOpen((prev) => !prev);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const {
    bookingData,
    restaurant,
    updateBookingData,
    updateStepValidationState,
    findNextInvalidStep,
  } = useCreateBookingFormContext();
  const { register, handleSubmit, formState, control, setError, clearErrors } =
    useForm<TableFormSchemaInput, unknown, TableFormSchema>({
      resolver: zodResolver(TableFormSchema, {
        errorMap: commonFormErrorMap,
      }),
      mode: "onSubmit",
      defaultValues: {
        placeId: "all",
        tables: bookingData.tables?.map(serializeTableData) || [],
      },
    });

  const handleTableStepSubmit = () => {
    let success = false;
    let data: BookingData;
    return handleSubmit(
      ({ tables }) => {
        data = updateBookingData({
          tables,
        });
        updateStepValidationState({ step4: true });
        success = true;
      },
      () => {
        updateStepValidationState({ step4: false });
        success = false;
      },
    )().then(
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
    );
  };

  const handleTableSelection = (tables: TableDetails[]) => {
    updateBookingData({ tables });
    updateStepValidationState({ step4: true });
    const nextStep = findNextInvalidStep(STEP_NUMBER);
    navigate(`../step-${nextStep || 5}`);
  };

  return (
    <>
      <StepContainer>
        <form className={styles.tableForm}>
          <label className={styles.label}>
            {formatMessage(
              { id: ETranslations.BASE_SELECT_ENTITY },
              {
                entity: formatMessage({
                  id: ETranslations.PLURAL_TABLE,
                }).toLowerCase(),
              },
            )}
          </label>
          <HallSelect
            name="placeId"
            control={control}
            restaurantId={restaurant.id}
            shiftId={bookingData.shift.shiftId}
          >
            <Button
              className={styles.hallButton}
              variant="phantom"
              onClick={handleHallModalToggle}
            >
              <ICONS.Floorplan />
            </Button>
          </HallSelect>
          <TableRadioGroup
            name="tables"
            restaurantId={restaurant.id}
            date={bookingData.bookingDate}
            persons={bookingData.persons}
            shiftId={bookingData.shift.shiftId}
            time={bookingData.bookingTime}
            visitDuration={bookingData.visitDuration}
            control={control}
          >
            {(seat, placeId) => (
              <RadioGroup.Button
                className={styles.hallTable}
                key={seat.table_id}
                value={serializeTableData({
                  placeId,
                  tableId: seat.table_id,
                  tableName: seat.table_number,
                })}
                {...register("tables", { required: true })}
              >
                {`${formatMessage({
                  id: ETranslations.PLURAL_TABLE,
                })} ${seat.table_number}`}
                <IconWithCaption
                  className={styles.capacity}
                  caption={`${seat.min_capacity || 1}-${seat.max_capacity || 0}`}
                >
                  <ICONS.People />
                </IconWithCaption>
              </RadioGroup.Button>
            )}
          </TableRadioGroup>
          {/* ВАЖНО: компонент использует а) ресторан, б) зал и в) время из глобального стора */}
          {isHallModalOpen && (
            <TableModal
              // HINT: та же самая строка, что в шаге 2
              reservationInfo={
                bookingData.persons && bookingData.bookingTime
                  ? formatTimeAndPersons({
                      time: bookingData.bookingTime,
                      persons: bookingData.persons,
                      formatMessage,
                    })
                  : ""
              }
              control={control}
              restaurantId={restaurant.id}
              date={bookingData.bookingDate}
              persons={bookingData.persons}
              shiftId={bookingData.shift.shiftId}
              time={bookingData.bookingTime}
              visitDuration={bookingData.visitDuration}
              onTablesChoose={handleTableSelection}
              onClose={handleHallModalToggle}
            />
          )}
        </form>
      </StepContainer>
      {formState.isValid && (
        <CreateBookingFormFooter
          currentStep={STEP_NUMBER}
          onBeforeNavigate={handleTableStepSubmit}
        />
      )}
    </>
  );
};
