import localforage from "localforage";
import { z } from "zod";

import { APP_NAME } from "../../constants";

// Типизация данных стора
interface StoreOptions<T extends ReturnType<typeof z.object>> {
  name: string;
  description?: string;
  schema: T;
}

export class LocalForageStore<T extends ReturnType<typeof z.object>> {
  private readonly instance: LocalForage;
  private readonly schema: T;

  constructor(options: StoreOptions<T>) {
    this.instance = localforage.createInstance({
      name: APP_NAME,
      storeName: options.name,
      description: options.description,
    });
    this.schema = options.schema;
  }

  setItem<K extends keyof z.infer<T>>(
    key: K extends string ? K : never,
    value: z.infer<T>[K],
  ): Promise<void> {
    return this.instance.setItem(key, value);
  }

  getItem<K extends keyof z.infer<T>>(
    key: K extends string ? K : never,
  ): Promise<z.infer<T>[K] | null> {
    return this.instance
      .getItem(key)
      .then((value) => {
        if (value === null) return null;

        // Парсинг через схему Zod
        return this.schema.shape[key].parse(value) as z.infer<T>[K];
      })
      .catch(() => {
        this.clear();
        return null;
      });
  }

  removeItem<K extends keyof z.infer<T>>(
    key: K extends string ? K : never,
  ): Promise<void> {
    return this.instance.removeItem(key);
  }

  clear(): Promise<void> {
    return this.instance.clear();
  }

  getAllItems(): Promise<Partial<z.infer<T>>> {
    const items: Record<string, unknown> = {};
    return this.instance
      .iterate((value, key) => void (items[key] = value))
      .then(() => this.schema.partial().parse(items));
  }
}
