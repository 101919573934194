import { selectedPlacesSelector } from "features/AppContext";
import { useSelector } from "react-redux";
import { Notification } from "services/notification";
import type { PlaceTable, PlaceTablesDto, TableFigure } from "types/table";



import { IResponse } from "../../models/common";
import { api } from "./api";


type TablesInfo = { tableId: number; tableNumber: string; busy: boolean };

type PlaceTablesById = Record<PlaceTablesDto["place_id"], PlaceTablesDto>;

type TablesById = Record<PlaceTable["table_id"], PlaceTable>;

type PlacesTables = {
  places: PlaceTablesDto[];
  tableById: TablesById;
  placeById: PlaceTablesById;
};

export const tablesApi = api
  .enhanceEndpoints({
    addTagTypes: ["TableOptions"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getPlacesTables: build.query<PlacesTables, number[]>({
        query: (places) => ({
          url: "v2/tables",
          method: "GET",
          params: { places },
        }),
        providesTags: (_res, _err, places) => [
          { type: "PlacesTables", id: String(places) },
        ],
        transformResponse: (response: IResponse<PlaceTablesDto[]>) =>
          response.data.reduce<PlacesTables>(
            (res, dto) => {
              const arc = { ...dto };
              arc.tables = [...arc.tables].sort(
                (a, b) => Number(a.number) - Number(b.number),
              );
              arc.tables.forEach((t) => (res.tableById[t.table_id] = t));
              res.placeById[dto.place_id] = arc;
              return res;
            },
            {
              places: response.data,
              tableById: {},
              placeById: {},
            },
          ),
      }),
      getTableFigures: build.query<TableFigure[], void>({
        query: () => ({
          url: "v2/tables/figures",
          method: "GET",
        }),
        transformResponse: (response: IResponse<TableFigure[]>) =>
          response.data,
      }),
      getTablesInfo: build.query<
        { info: TablesInfo[]; busyTablesIds: number[] },
        {
          placeId: number;
          restaurantId: number;
          bookingDate: string;
          bookingTime: string;
          visitTime: number;
        }
      >({
        query: ({ placeId, ...body }) => ({
          url: `/v2/hall-schema/places/${placeId}`,
          method: "POST",
          body,
        }),
        transformResponse: (response: IResponse<TablesInfo[]>) => ({
          info: response.data,
          busyTablesIds: response.data.reduce(
            (result, table) => (
              table.busy && result.push(table.tableId), result
            ),
            new Array<number>(),
          ),
        }),
        providesTags: ["TableOptions"],
        keepUnusedDataFor: 0,
      }),
    }),
  });

export const {
  useGetPlacesTablesQuery,
  useGetTablesInfoQuery,
  useGetTableFiguresQuery,
} = tablesApi;

export const useTableFigures = () => {
  const figuresData = useGetTableFiguresQuery();
  const defaultFigure: TableFigure = {
    figure_id: "2",
    height: 70,
    id: 16,
    shape: "square",
    shape_type: "M",
    width: 70,
  }; //средний квадрат
  return { ...figuresData, defaultFigure };
};

export function usePlacesTable(tableId: number | null | undefined) {
  const places = useSelector(selectedPlacesSelector);
  const { data, ...rest } = useGetPlacesTablesQuery(places);

  const table = data?.tableById[Number(tableId)];

  return {
    ...rest,
    table,
  };
}