import cn from "classnames";
import type { SeatType } from "models/booking.model";
import type { Tag } from "models/tags.model";
import {
  type MouseEventHandler,
  type ReactNode,
  type Ref,
  type TouchEventHandler,
  forwardRef,
  memo,
} from "react";
import { NavLink, type NavLinkProps } from "react-router-dom";
import type { StatusSystemName } from "types/status";
import { ICONS, IconWithCaption } from "ui-kit";
import { ProgressRing } from "ui-kit/ProgressRing";

import { SEAT_TYPE_ICONS } from "../../constants";
import { BookingTags } from "./BookingTags";
import styles from "./SlotCard.module.scss";
import { tagsToShortView } from "./utils";

type SlotCardRootProps = {
  className?: string;
  wrapperClassName?: string;
  statusClassName?: Lowercase<StatusSystemName> | "management";
  actions?: ReactNode;
  children: ReactNode;
  onMouseDown?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  onTouchStart?: TouchEventHandler<HTMLElement>;
  onTouchEnd?: TouchEventHandler<HTMLElement>;
  onTouchCancel?: TouchEventHandler<HTMLElement>;
  onContextMenuCapture?: MouseEventHandler<HTMLElement>;
} & (
  | {
      as: "link";
      to: string;
      onClick?: () => void;
      wrapperClassName?: NavLinkProps["className"];
    }
  | {
      as: "button";
      to?: never;
      onClick: () => void;
    }
  | {
      as?: "div";
      to?: never;
      onClick?: () => void;
    }
);

const Root = forwardRef<HTMLElement, SlotCardRootProps>(
  (
    {
      as,
      to,
      onClick,
      className,
      wrapperClassName,
      statusClassName,
      actions,
      children,
      onMouseDown,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onTouchCancel,
      onContextMenuCapture,
    },
    ref,
  ) => (
    <article
      key={statusClassName}
      className={cn(styles.slotCard, styles[statusClassName || ""], className)}
    >
      {as === "link" ? (
        <NavLink
          ref={ref as Ref<HTMLAnchorElement>}
          className={cn(styles.cardWrapper, wrapperClassName)}
          to={to!}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchCancel={onTouchCancel}
          onContextMenuCapture={onContextMenuCapture}
        >
          {children}
        </NavLink>
      ) : as === "button" ? (
        <button
          ref={ref as Ref<HTMLButtonElement>}
          onClick={onClick}
          className={cn(styles.cardWrapper, wrapperClassName)}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchCancel={onTouchCancel}
          onContextMenuCapture={onContextMenuCapture}
        >
          {children}
        </button>
      ) : (
        <div
          ref={ref as Ref<HTMLDivElement>}
          className={cn(styles.cardWrapper, wrapperClassName)}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchCancel={onTouchCancel}
          onContextMenuCapture={onContextMenuCapture}
        >
          {children}
        </div>
      )}
      {actions && <aside className={styles.actions}>{actions}</aside>}
    </article>
  ),
);

Root.displayName = "SlotCardRoot";

const Header = ({
  header,
  headerClassName,
}: {
  header: ReactNode;
  headerClassName?: string;
}) => <header className={cn(styles.header, headerClassName)}>{header}</header>;

Header.displayName = "SlotCardHeader";

const Content = ({
  vip,
  name,
  nameAddon,
  phone,
  tags,
  visibleTagsCount,
  children,
  extraInfo,
  contentClassName,
}: {
  vip?: boolean | null;
  name: string;
  nameAddon?: string;
  phone?: string;
  tags?: Tag[];
  visibleTagsCount?: number;
  children?: ReactNode;
  extraInfo?: ReactNode;
  contentClassName?: string;
}) => {
  const formattedTags = tags && tagsToShortView(tags, visibleTagsCount ?? 2);
  const hasBottomContentSection = Boolean(
    phone || formattedTags?.visibleTags.length || extraInfo,
  );

  return (
    <section className={cn(styles.content, contentClassName)}>
      <div>
        <hgroup>
          {vip && <ICONS.VipSign />}
          <h2 className={styles.cardName}>{name}</h2>
          {nameAddon && <em>&nbsp;·&nbsp;{nameAddon}</em>}
        </hgroup>
        {children}
      </div>
      {hasBottomContentSection && (
        <div>
          <p className={styles.cardPhone}>
            {phone && <span>{phone}</span>}
            {formattedTags && Boolean(formattedTags.visibleTags.length) && (
              <BookingTags tags={formattedTags} />
            )}
          </p>

          {extraInfo && <p>{extraInfo}</p>}
        </div>
      )}
    </section>
  );
};

Content.displayName = "SlotCardContent";

const AdditionalInfo = memo(
  ({
    seatType,
    date,
    visitProgress,
    time,
    visitDuration,
    visitors,
    tables,
    hallName,
    comment,
    warning,
  }: {
    seatType?: SeatType;
    date?: string;
    visitProgress?: number;
    time: string;
    visitDuration: `${number}:${number}`;
    visitors?: number;
    tables?: string;
    hallName?: string;
    comment?: string;
    warning?: string | false;
  }) => {
    const SeatTypeIcon = seatType && SEAT_TYPE_ICONS[seatType];
    return (
      <footer className={styles.footer}>
        <section className={styles.primaryInfo}>
          <div>
            {SeatTypeIcon && <SeatTypeIcon />}
            {date && (
              <IconWithCaption className={styles.date} caption={date}>
                <ICONS.Calendar />
              </IconWithCaption>
            )}
            <IconWithCaption
              className={styles.time}
              caption={`${time}\u00A0|\u00A0${visitDuration}`}
            >
              {visitProgress ? (
                <ProgressRing percent={visitProgress} />
              ) : (
                <ICONS.Clock />
              )}
            </IconWithCaption>
          </div>
          {visitors && (
            <IconWithCaption caption={visitors}>
              <ICONS.People />
            </IconWithCaption>
          )}
          {tables && (
            <IconWithCaption
              caption={tables}
              className={styles.tablesContainer}
              captionClassName={styles.tables}
            >
              <ICONS.Seat />
            </IconWithCaption>
          )}
        </section>
        <section className={styles.secondaryInfo}>
          {hallName && (
            <IconWithCaption
              caption={hallName}
              captionClassName={styles.hallName}
            >
              <ICONS.Hall />
            </IconWithCaption>
          )}
          {comment && (
            <IconWithCaption
              caption={comment}
              captionClassName={styles.comment}
              title={comment}
            >
              <ICONS.Comment />
            </IconWithCaption>
          )}
        </section>
        {warning && (
          <IconWithCaption className={styles.warningHint} caption={warning}>
            <ICONS.Danger />
          </IconWithCaption>
        )}
      </footer>
    );
  },
);

AdditionalInfo.displayName = "SlotCardAdditionalInfo";

export const SlotCard = {
  Root,
  Header,
  Content,
  AdditionalInfo,
};
