import { DepositStatus } from "containers/CreateBookingForm/model";
import { SourceId } from "models/source.model";
import { Tag } from "models/tags.model";
import type { IntlShape } from "react-intl";
import { z } from "zod";

export const getExtraFormSchema = (t: IntlShape["formatMessage"]) =>
  z.object({
    tags: Tag.array(),
    comment: z.string().optional(),
    deposit: DepositStatus,
    source: z
      .object({
        id: SourceId,
        name: z.string(),
      })
      .optional(),
  });

export type ExtraFormSchema = z.infer<ReturnType<typeof getExtraFormSchema>>;
