import { z } from "zod";

import { TableId } from "./booking.model";
import { PlaceId } from "./restaurant.model";
import { ShiftId } from "./shift.model";

export const ShiftSlotDTO = z.object({
  shift_id: ShiftId,
  date: z.string().date(),
  shift_name: z.string(),
  party_min_size: z.number(),
  party_max_size: z.number(),
  max_covered_in_use: z.boolean(),
  max_covered_in_size: z.number(),
  time_interval: z.number(),
  start_datetime: z.string().datetime({ local: true }),
  end_datetime: z.string().datetime({ local: true }),
});
export type ShiftSlotDTO = z.infer<typeof ShiftSlotDTO>;

const Seat = z.object({
  table_id: TableId,
  table_number: z.string(),
  max_capacity: z.number(),
  min_capacity: z.number(),
});
export type Seat = z.infer<typeof Seat>;

const HallAvailability = z.object({
  hall_id: PlaceId,
  hall_name: z.string(),
  hall_weight: z.number(),
  seats: Seat.array(),
});
export type HallAvailability = z.infer<typeof HallAvailability>;

export const SeatSlotDTO = z.object({
  availability: HallAvailability.array(),
  recommendations: z.object({}).array(),
});
export type SeatSlotDTO = z.infer<typeof SeatSlotDTO>;

export const TimeSlotDTO = z.object({
  time_zone: z.string(),
  zoned_timestamp: z.number(),
  time_short_name: z.string().time(),
  slot_covered_in: z.number(),
  slot_max_covered_in: z.number(),
});
export type TimeSlotDTO = z.infer<typeof TimeSlotDTO>;
