import cn from "classnames";
import {
  type ComponentType,
  type FormHTMLAttributes,
  type MouseEvent,
  type ReactNode,
  type RefObject,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { Button, type ButtonProps } from "ui-kit/Button";
import { ICONS } from "ui-kit/ICONS";

import styles from "./ModalDialog.module.scss";
import { modalDialogContext, useModalDialogContext } from "./context";

const ModalDialogContent = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => {
  const { dialogRef, closeDialog, onCancel } = useModalDialogContext();

  const handleClickOutside = (e: MouseEvent) => {
    onCancel?.();
    dialogRef.current === e.target && closeDialog();
  };
  return (
    <dialog
      ref={dialogRef}
      className={cn(styles.dialogContainer, className)}
      onClick={handleClickOutside}
    >
      {children}
    </dialog>
  );
};

ModalDialogContent.displayName = "ModalDialogContent";

const ModalDialogTrigger = ({
  as,
  variant,
  ...props
}:
  | ({ as: ComponentType<ButtonProps> } & Partial<ButtonProps>)
  | ({ as: undefined } & ButtonProps)) => {
  const { openDialog } = useModalDialogContext();
  const Trigger = as || Button;
  return (
    <Trigger
      variant={variant || "phantom"}
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        openDialog();
      }}
    />
  );
};

ModalDialogTrigger.displayName = "ModalDialogTrigger";

const ModalDialogPortal = ({ children }: { children: ReactNode }) => {
  const { dialogRef } = useModalDialogContext();
  return dialogRef.current && createPortal(children, dialogRef.current);
};

ModalDialogPortal.displayName = "ModalDialogPortal";

const ModalDialogForm = ({
  className,
  children,
  ...props
}: {
  className?: string;
  children: ReactNode;
} & FormHTMLAttributes<HTMLFormElement>) => {
  const { formId } = useModalDialogContext();
  return (
    <form
      id={formId}
      className={cn(styles.dialogForm, className)}
      method="dialog"
      {...props}
    >
      {children}
    </form>
  );
};

ModalDialogForm.displayName = "DialogForm";

const ModalDialogCloseButton = ({
  className,
  children,
  variant,
  isCancelButton,
  ...props
}: {
  className?: string;
  children?: ReactNode;
  isCancelButton?: boolean;
} & Partial<ButtonProps>) => {
  const { formId, closeDialog, onCancel } = useModalDialogContext();
  return (
    <Button
      {...props}
      className={cn(isCancelButton || styles.closeButton, className)}
      form={formId}
      type="reset"
      variant={variant || "phantom"}
      onClick={() => {
        onCancel?.();
        closeDialog();
      }}
    >
      {children || <ICONS.Cross />}
    </Button>
  );
};

ModalDialogCloseButton.displayName = "DialogCloseButton";

const ModalDialog = forwardRef<
  HTMLDialogElement,
  {
    children: ReactNode;
    id?: string;
    open?: boolean;
    onClose?: () => void;
    onCancel?: () => void;
  }
>(({ children, id, open, onClose, onCancel }, ref) => {
  const internalRef = useRef<HTMLDialogElement>(null);
  const dialogRef =
    (ref as RefObject<HTMLDialogElement> | undefined) || internalRef;

  useEffect(() => {
    if (onClose) {
      dialogRef.current?.addEventListener("close", onClose);
      return () => dialogRef.current?.removeEventListener("close", onClose);
    }
  }, [onClose]);

  useEffect(() => {
    if (onCancel) {
      dialogRef.current?.addEventListener("cancel", onCancel);
      return () => dialogRef.current?.removeEventListener("cancel", onCancel);
    }
  }, [onCancel]);

  useEffect(() => {
    open && dialogRef.current?.showModal();
  }, [open]);

  return (
    <modalDialogContext.Provider
      value={{
        dialogRef,
        formId: id || "dialogForm",
        openDialog: () => {
          dialogRef.current?.showModal();
        },
        closeDialog: (returnValue?: string) =>
          dialogRef.current?.close(returnValue),
        onCancel,
      }}
    >
      {children}
    </modalDialogContext.Provider>
  );
});

ModalDialog.displayName = "ModalDialog";

export {
  ModalDialog,
  ModalDialogCloseButton,
  ModalDialogForm,
  ModalDialogTrigger,
  ModalDialogContent,
  ModalDialogPortal,
};
