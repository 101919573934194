import cn from "classnames";
import type { ReactNode } from "react";

import styles from "./FormHeader.module.scss";

const FormHeader = ({
  title,
  children,
  className,
  titleClassName,
}: {
  title: string;
  children: ReactNode;
  className?: string;
  titleClassName?: string;
}) => {
  return (
    <header className={cn(styles.header, className)}>
      <h2 className={cn(styles.title, titleClassName)}>{title}</h2>
      {children}
    </header>
  );
};
FormHeader.displayName = "FormHeader";
export { FormHeader };
