import type { Dispatch, SetStateAction } from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

export type TextAreaContext = {
  name?: string;
  maxLength: number;
  defaultValue?: string;
  counter: number;
  setCounter: Dispatch<SetStateAction<number>>;
};

const TextAreaContext = createContext<TextAreaContext>(null!);

export const useTextAreaContextSelector = <Selected>(
  selector: (context: TextAreaContext) => Selected,
) => useContextSelector<TextAreaContext, Selected>(TextAreaContext, selector);

export const useTextAreaContext = () => useContext(TextAreaContext);

const TextAreaContextProvider = TextAreaContext.Provider;
TextAreaContextProvider.displayName = "TextAreaContextProvider";
export { TextAreaContextProvider };
