import cn from "classnames";
import { type CSSProperties, ComponentType, PropsWithChildren } from "react";
import { Button, ButtonProps, LinkButton } from "ui-kit";

import styles from "./Menu.module.scss";

type ItemProps = {
  className?: string;
  style?: CSSProperties;
  icon?: ComponentType;
  disabled?: boolean;
} & Pick<ButtonProps, "onClick"> &
  (
    | { as: "link"; to: string; state?: any }
    | { as?: "button"; to?: undefined; state?: undefined }
  );
function Item({
  children,
  className,
  style,
  onClick,
  icon: Icon,
  as,
  to,
  state,
  disabled,
}: PropsWithChildren<ItemProps>) {
  return (
    <li className={styles.item}>
      {as === "link" ? (
        <LinkButton
          variant="phantom"
          className={cn(styles.button, className)}
          to={to}
          state={state}
        >
          {Icon && (
            <span className={styles.icon}>
              <Icon />
            </span>
          )}
          {children}
        </LinkButton>
      ) : (
        <Button
          variant="phantom"
          className={cn(styles.button, className)}
          style={style}
          onClick={onClick}
          disabled={disabled}
        >
          {Icon && (
            <span className={styles.icon}>
              <Icon />
            </span>
          )}
          {children}
        </Button>
      )}
    </li>
  );
}

export const Menu = Object.assign(
  ({
    children,
    className,
    onClick,
    disableDnD,
  }: PropsWithChildren<{
    className?: string;
    onClick?: React.MouseEventHandler<HTMLOListElement>;
    disableDnD?: boolean;
  }>) => (
    <menu
      className={cn(styles.menu, className)}
      onClick={onClick}
      {...(disableDnD
        ? {
            onMouseDown: (e) => {
              e.stopPropagation();
            },
            onTouchStart: (e) => {
              e.stopPropagation();
            },
            onTouchMove: (e) => {
              e.stopPropagation();
            },
            onTouchStartCapture: (e) => {
              e.stopPropagation();
            },
            onScroll: (e) => {
              e.stopPropagation();
            },
            onScrollCapture: (e) => {
              e.stopPropagation();
            },
            onDragStart: (e) => {
              e.stopPropagation();
            },
          }
        : {})}
    >
      {children}
    </menu>
  ),
  {
    Item,
  },
);
