import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS, RadioGroup } from "ui-kit";

import styles from "./ShiftRadioGroup.module.scss";
import { useMultiShiftNavigation } from "./useMultiShiftNavigation";

const MultiShiftRadioButtons = ({ children }: { children: ReactNode }) => {
  const { buttonsRef, links, prev, next } = useMultiShiftNavigation();
  return (
    <>
      {links ? (
        <a className={styles.arrow} href={prev}>
          <ICONS.LeftArrow />
        </a>
      ) : undefined}
      <RadioGroup.Content className={styles.multiShiftButtons} ref={buttonsRef}>
        {children}
      </RadioGroup.Content>
      {links ? (
        <a className={styles.arrow} href={next}>
          <ICONS.RightArrow />
        </a>
      ) : undefined}
    </>
  );
};

MultiShiftRadioButtons.displayName = "MultiShiftRadioButtons";
export { MultiShiftRadioButtons };
