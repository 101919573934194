import cn from "classnames";
import { getCrossIcon } from "common/helpers";
import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { FieldError } from "ui-kit";

import { Labeled } from "../Labeled";
import styles from "./Input.module.scss";
import { InputAddon } from "./InputAddon";

type BaseInputProps = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends Omit<BaseInputProps, "prefix"> {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputClassName?: string;
  invalid?: boolean;
  datalist?: ReactNode;
  error?: string | string[];
  isClearable?: boolean;
  errorTranslationValue?: string;
  onClear?: () => void;
}

export const Input = forwardRef(
  (
    {
      label,
      className,
      prefix,
      suffix,
      required,
      inputClassName,
      invalid,
      datalist,
      error,
      isClearable,
      onClear,
      errorTranslationValue,
      placeholder,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { formatMessage } = useIntl();
    return (
      <Labeled className={className} label={label} required={required}>
        <div className={styles.inputContainer}>
          <InputAddon className={styles.prefix}>{prefix}</InputAddon>
          <input
            {...props}
            placeholder={
              placeholder ??
              formatMessage(
                { id: ETranslations.BASE_ENTER_VALUE },
                {
                  value: `${label || ""}`.toLowerCase(),
                },
              )
            }
            className={cn(styles.input, inputClassName, {
              [styles.withPrefix]: !!prefix,
              [styles.withSuffix]: !!suffix,
              [styles.invalid]: invalid || error,
              [styles.clearable]: isClearable,
            })}
            ref={ref}
          />
          {isClearable && props.value && (
            <div className={styles.clear}>
              {getCrossIcon(onClear ?? (() => {}))}
            </div>
          )}
          {datalist}
          <InputAddon className={styles.suffix}>{suffix}</InputAddon>
        </div>
        {error && (
          <FieldError
            error={error}
            fieldName={`${label}`}
            value={errorTranslationValue}
            max={props.max}
          />
        )}
      </Labeled>
    );
  },
);
