import cn from "classnames";
import type { ReactNode } from "react";

import styles from "./FormFooter.module.scss";

const FormFooter = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => {
  return <footer className={cn(styles.footer, className)}>{children}</footer>;
};

FormFooter.displayName = "FormFooter";
export { FormFooter };
