import type { Client } from "containers/CreateBookingForm/model";
import type { ReactNode } from "react";

import { CreateClientForm } from "./CreateClientForm";
import { RegisterContactForm } from "./RegisterContactForm";

export const ClientForm = ({
  formId,
  clientFormType,
  children,
  setIsValid,
  onSubmit,
}: {
  formId?: string;
  clientFormType: "CLIENT" | "CONTACT";
  children: ReactNode;
  setIsValid: (isValid: boolean) => void;
  onSubmit: (client: Client) => void;
}) => (
  <section>
    {children}
    {clientFormType === "CONTACT" ? (
      <RegisterContactForm
        formId={formId}
        setIsValid={setIsValid}
        onSubmit={onSubmit}
      />
    ) : (
      <CreateClientForm
        formId={formId}
        setIsValid={setIsValid}
        onSubmit={onSubmit}
      />
    )}
  </section>
);
