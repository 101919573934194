import { useState } from "react";

type ClientInfoStepState =
  | {
      isClientListOpen: true;
      clientForm?: { type: "CLIENT" | "CONTACT"; isValid: boolean };
    }
  | {
      isClientListOpen: false;
      clientForm: { type: "CLIENT" | "CONTACT"; isValid: boolean };
    };

export const useClientInfoStepState = () => {
  const [clientInfoStepState, setClientInfoStepState] =
    useState<ClientInfoStepState>({
      isClientListOpen: true,
    });
  const openClientForm = (formType: "CLIENT" | "CONTACT") =>
    setClientInfoStepState((prev) => ({
      isClientListOpen: false,
      clientForm: {
        type: formType,
        isValid:
          prev.clientForm?.type === formType ? prev.clientForm.isValid : false,
      },
    }));
  const closeClientForm = () =>
    setClientInfoStepState((prev) => ({
      ...prev,
      isClientListOpen: true,
    }));
  const changeClientFormValidation = (isValid: boolean) =>
    isValid !== clientInfoStepState.clientForm?.isValid &&
    setClientInfoStepState((prev) => ({
      isClientListOpen: false,
      clientForm: { type: prev.clientForm?.type || "CLIENT", isValid },
    }));
  return {
    isClientListOpen: clientInfoStepState.isClientListOpen,
    isClientFormValid: !!clientInfoStepState.clientForm?.isValid,
    clientFormType: clientInfoStepState.clientForm?.type || "CLIENT",
    openClientForm,
    closeClientForm,
    changeClientFormValidation,
  };
};
