import { bookingFormLocalStore } from "containers/CreateBookingForm/localStore/bookingFormLocalStore";
import {
  BookingData,
  type RestaurantInfo,
  type StepNumber,
  type StepValidationState,
} from "containers/CreateBookingForm/model";
import { type ReactNode, useState } from "react";
import { useLoaderData } from "react-router-dom";

import { loader } from "../api/loader";
import { createBookingFormContext } from "../createBookingFormContext";

const CreateBookingFormProvider = ({ children }: { children: ReactNode }) => {
  const {
    defaultBookingDate,
    defaultBookingTime,
    defaultRestaurant,
    cachedRestaurant,
    restaurantsData,
    defaultShiftDetails,
    userId,
    cachedBookingData,
  } = useLoaderData<typeof loader>();

  const [bookingData, setBookingData] = useState<BookingData>({
    bookingDate: defaultBookingDate,
    bookingTime: defaultBookingTime,
    shift: defaultShiftDetails.defaultValue,
    persons: 2,
    visitDuration: 30,
    userId,
  });
  const [stepValidationState, setStepValidationState] =
    useState<StepValidationState>({
      step1: undefined,
      step2: undefined,
      step3: undefined,
      step4: undefined,
      step5: undefined,
    });
  const [restaurant, setRestaurant] = useState(defaultRestaurant);

  const updateBookingData = (newValues: Partial<BookingData>): BookingData => {
    const newBookingData = { ...bookingData, ...newValues };
    bookingFormLocalStore.setItem("bookingData", newBookingData);
    setBookingData(newBookingData);
    return newBookingData;
  };

  const updateStepValidationState = (
    newStepValidationState: Partial<StepValidationState>,
  ) =>
    Object.entries(newStepValidationState).some(
      (state) =>
        stepValidationState[state[0] as `step${StepNumber}`] !== state[1],
    ) &&
    setStepValidationState((prev) => ({
      ...prev,
      ...newStepValidationState,
    }));

  const changeRestaurant = (newRestaurant: RestaurantInfo) => {
    bookingFormLocalStore.setItem("restaurant", newRestaurant);
    updateStepValidationState({
      step1: undefined,
      step2: undefined,
      step4: undefined,
    });
    setRestaurant(newRestaurant);
  };

  const findNextInvalidStep = (
    currentStep: StepNumber,
    newValidationState?: Partial<StepValidationState>,
  ) => {
    const currentValidationState = newValidationState
      ? { ...stepValidationState, ...newValidationState }
      : stepValidationState;
    return (Object.values(currentValidationState).findIndex(
      (valid, index) => !valid && index + 1 !== currentStep,
    ) + 1 || undefined) as StepNumber | undefined;
  };
  return (
    <createBookingFormContext.Provider
      value={{
        bookingData,
        cachedBookingData,
        stepValidationState,
        restaurant,
        cachedRestaurant,
        restaurantsData,
        findNextInvalidStep,
        updateBookingData,
        updateStepValidationState,
        changeRestaurant,
      }}
    >
      {children}
    </createBookingFormContext.Provider>
  );
};

CreateBookingFormProvider.displayName = "CreateBookingFormProvider";

export { CreateBookingFormProvider };
