import cn from "classnames";
import { useRestsOptions } from "features/AppContext/selectors";
import type { RestaurantId } from "models/restaurant.model";
import type { UserGroupId } from "models/user.model";
import { type ChangeEvent, type ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { Form, useActionData } from "react-router-dom";
import { ETranslations } from "types/translates";
import { FieldError, Input, SelectBasic } from "ui-kit";

import type { createUserAction } from "../api/createUserAction";
import type { editUserAction } from "../api/editUserAction";
import { USER_INTENT } from "../constants";
import { RoleSelect } from "./RoleSelect";
import styles from "./UserForm.module.scss";

const LoginInput = ({
  login,
  readOnly,
  label,
  error,
}: {
  login?: string;
  readOnly: boolean;
  label: string;
  error?: string | string[];
}) => {
  const [value, setValue] = useState(login);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    // Удаляем все символы, кроме латинских букв и цифр
    const filteredValue = inputValue.replace(/\W/g, "");
    setValue(filteredValue);
  };
  return (
    <Input
      className={styles.spanAllColumns}
      inputClassName={styles.login}
      required
      autoComplete="off"
      name="login"
      type="text"
      minLength={3}
      maxLength={50}
      value={value}
      readOnly={readOnly}
      label={label}
      error={error}
      errorTranslationValue="50"
      onChange={handleChange}
    />
  );
};

export const UserForm = ({
  intent,
  fullName,
  extensionPhone,
  email,
  phone,
  restaurants,
  roleId,
  login,
  children,
}: {
  intent: keyof typeof USER_INTENT;
  fullName?: string;
  extensionPhone?: string;
  email?: string;
  phone?: string;
  restaurants?: RestaurantId[];
  roleId?: UserGroupId;
  login?: string;
  children?: ReactNode;
}) => {
  const actionData = useActionData<
    typeof editUserAction | typeof createUserAction
  >();
  const { formatMessage } = useIntl();
  const restsOptions = useRestsOptions();

  return (
    <Form className={styles.userForm} id={intent} method="POST">
      <Input
        autoComplete="off"
        name="fullName"
        defaultValue={fullName}
        label={formatMessage({ id: ETranslations.FULL_NAME })}
        required
        error={actionData?.fieldErrors?.fullName}
      />
      <Input
        autoComplete="off"
        name="phone"
        defaultValue={phone}
        label={formatMessage({ id: ETranslations.PHONE })}
        error={actionData?.fieldErrors?.phone}
      />
      <Input
        autoComplete="off"
        name="email"
        defaultValue={email}
        label={formatMessage({ id: ETranslations.EMAIL })}
        error={actionData?.fieldErrors?.email}
      />
      <Input
        autoComplete="off"
        name="extensionPhone"
        defaultValue={extensionPhone}
        label={formatMessage({ id: ETranslations.EXTENSION_PHONE })}
        error={actionData?.fieldErrors?.extensionPhone}
      />
      <SelectBasic
        className={styles.spanAllColumns}
        label={formatMessage({ id: ETranslations.PLURAL_RESTAURANT })}
        isMulti
        styleObject={{
          multiValue: {
            borderRadius: "20px",
            font: "var(--font-12-b)",
            color: "var(--gl_text_constant_primary2)",
            backgroundColor: "var(--tags_constant_background_5)",
          },
          multiValueLabel: {
            color: "var(--gl_text_constant_primary2)",
          },
        }}
        defaultValue={restsOptions.filter((option) =>
          restaurants?.includes(option.value),
        )}
        name="restaurant"
        options={restsOptions}
        required
        error={actionData?.fieldErrors?.restaurant}
      />
      <RoleSelect
        isDefaultEditRole={intent === USER_INTENT.CREATE}
        defaultRoleId={roleId}
        error={actionData?.fieldErrors?.role}
      />
      <hr className={cn(styles.divider, styles.spanAllColumns)} />
      <LoginInput
        login={login}
        readOnly={intent === USER_INTENT.EDIT}
        label={formatMessage({ id: ETranslations.AUTH_LOGIN })}
        error={actionData?.fieldErrors?.login}
      />
      {children}
      {actionData?.formErrors && <FieldError error={actionData.formErrors} />}
    </Form>
  );
};
