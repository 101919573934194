import { FunctionComponent, ReactNode } from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";
import { ICONS } from "ui-kit";

export interface ModalProps
  extends Omit<ReactModalProps, "onAfterClose" | "onRequestClose"> {
  onClose(): void;
  buttons: ReactNode;
  title: string;
  text: string;
  editBooking?: boolean;
  needIcon?: boolean;
}

type Props = {} & Omit<ModalProps, "children">;

const parentSelector = () => document.body;

const ManagerialModal: FunctionComponent<Props> = ({
  onClose,
  buttons,
  title,
  needIcon = true,
  text,
  editBooking,
  ...props
}) => {
  return (
    <ReactModal
      className="modal"
      closeTimeoutMS={0}
      overlayClassName="modalOverlay"
      parentSelector={parentSelector}
      portalClassName="modalPortal"
      role="dialog"
      shouldFocusAfterRender={false}
      ariaHideApp
      preventScroll
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
      onRequestClose={onClose}
      {...props}
    >
      {!needIcon ? null : editBooking ? (
        <ICONS.ErrorWarning />
      ) : (
        <ICONS.Attention />
      )}
      <div className="textContent">
        <p className="title">{title}</p>
        <p className="text">{text}</p>

        {buttons && <div className="buttonBox">{buttons}</div>}
      </div>
    </ReactModal>
  );
};

export default ManagerialModal;
