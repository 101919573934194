import { FormClose, FormHeader } from "components/MultiStepForm";
import { restaurantSelector } from "features/AppContext";
import { useGetTagsQuery } from "features/api/tags";
import _ from "lodash";
import { Tag } from "models/tags.model";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button, Collapse, SelectBasic, Spinner } from "ui-kit";
import { SearchInput } from "ui-kit/Input/SearchInput";
import { RadioTag } from "ui-kit/RadioTag/RadioTag";

import styles from "./TagsModal.module.scss";
import { groupFilterColors } from "./groupFilterColors";

/*
    ____,-------------------------------,____
    \   |           Компонент           |   /
    /___|-------------------------------|___\

*/

type Props = {
  initialValue: Tag[];
  onClose: () => void;
  onChange: (tags: Tag[]) => void;
};

type Section = {
  name: string;
  tags: Tag[];
  selected: number;
};

type GroupOptions = {
  label: string;
  value: string;
};

export function TagsModal({ initialValue, onClose, onChange }: Props) {
  const { formatMessage } = useIntl();
  const restaurant = useSelector(restaurantSelector);
  const [tags, setTags] = useState(initialValue);
  const [search, setSearch] = useState("");
  const [selectedGrops, setSelectedGroups] = useState<GroupOptions[]>([]);

  const tagsQuery = useGetTagsQuery({
    type: "BOOKING",
    owner_id: restaurant.restaurant_id,
    include_deleted: false,
  });

  const groups = useMemo(() => {
    if (!tagsQuery.data?.tags) return null;
    return _.groupBy(tagsQuery.data?.tags, "group");
  }, [tagsQuery.data?.tags]);

  const groupOptions = useMemo<GroupOptions[] | undefined>(() => {
    if (!groups) return undefined;
    return Object.keys(groups).map((g) => ({
      label: g,
      value: g,
      color: "var(--tags_constant_background_5)",
    }));
  }, [groups]);

  const sections = useMemo(() => {
    if (!groups) return null;

    const result: Section[] = [];

    Object.keys(groups).forEach((g) => {
      const s: Section = {
        name: g,
        tags: groups[g]!,
        selected: tags.filter((t) => t.group === g).length,
      };
      result.push(s);
    });

    return result.filter(
      (s) =>
        selectedGrops.length === 0 ||
        selectedGrops.find((sg) => sg.label === s.name),
    );
  }, [groups, tags, selectedGrops]);

  const handleChange = (key: Tag["id"], selected: boolean) => {
    if (!tagsQuery.data) return;

    if (selected) {
      setTags([...tags, tagsQuery.data.tagsById[key]!]);
    } else {
      setTags(tags.filter((t) => t.id !== key));
    }
  };

  return (
    <section className={styles.tagsModal}>
      <FormHeader
        title={formatMessage({
          id: ETranslations.SELECT_CLIENT_TAGS_MODAL_TITLE,
        })}
      >
        <div className={styles.headerInputs}>
          {/* FIXME: добавить clear */}
          <SearchInput
            className={styles.search}
            onSearch={setSearch}
            isClearable
          />
          <SelectBasic
            className={styles.groupFilter}
            closeMenuOnSelect={false}
            menuPlacement="bottom"
            openTo="bottom"
            placeholder={formatMessage({ id: ETranslations.ALL_GROUPS })}
            options={groupOptions}
            value={selectedGrops}
            styles={groupFilterColors}
            isClearable
            isMulti
            changedMaxSize={false}
            onChange={(v) => setSelectedGroups(v as GroupOptions[])}
          />
        </div>
        <FormClose onClose={onClose} />
      </FormHeader>

      <div className={styles.content}>
        {tagsQuery.isLoading && <Spinner />}
        {sections?.map((s) => (
          <Section
            key={s.name}
            data={s}
            selected={tags}
            search={search}
            onChange={handleChange}
          />
        ))}
      </div>

      <div className={styles.footer}>
        <Button
          variant="secondary"
          disabled={tags.length === 0}
          onClick={() => setTags([])}
        >
          {formatMessage({ id: ETranslations.BASE_RESET }, { entity: "" })}
        </Button>
        <div className={styles.mainButtons}>
          <Button variant="secondary" onClick={onClose}>
            {formatMessage({
              id: ETranslations.BASE_CANCEL,
            })}
          </Button>
          <Button
            variant="primary"
            disabled={tags === initialValue}
            onClick={() => onChange(tags)}
          >
            {formatMessage({
              id: ETranslations.APPLY,
            })}
          </Button>
        </div>
      </div>
    </section>
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\

*/

function Section({
  data,
  selected,
  search,
  onChange,
}: {
  data: Section;
  selected: Tag[];
  search?: string;
  onChange?: (key: Tag["id"], selected: boolean) => void;
}) {
  const filtered = search
    ? data.tags.filter((t) => t.name.includes(search))
    : data.tags;

  if (filtered.length === 0) return null;

  return (
    <Collapse
      initialOpen
      expendInHeader={true}
      headerClassName={styles.groupHeader}
      header={
        <div className={styles.groupHeaderTitle}>
          {data.name}{" "}
          <span className={styles.groupHeaderStats}>
            {data.selected}/{data.tags.length}
          </span>
        </div>
      }
    >
      <div className={styles.groupContent}>
        {filtered.map((t) => (
          <RadioTag
            key={t.id}
            name={t.id}
            checked={!!selected.find((t2) => t.id === t2.id)}
            color={t.color}
            onChange={onChange}
          >
            {t.name}
          </RadioTag>
        ))}
      </div>
    </Collapse>
  );
}
