import { FormHeader } from "components/MultiStepForm";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";

import { CrateBookingFormCloseButton } from "./CreateBookingFormCloseButton";
import styles from "./CreateBookingFormHeader.module.scss";
import { RestaurantSelect } from "./RestaurantSelect";

export const CreateBookingFormHeader = () => {
  const { formatMessage } = useIntl();
  return (
    <FormHeader
      className={styles.createBookingFormHeader}
      title={formatMessage({ id: ETranslations.CREATION_RESERVATION })}
    >
      /
      <RestaurantSelect />
      <CrateBookingFormCloseButton />
    </FormHeader>
  );
};
