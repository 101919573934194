import { router } from "components/root/routes";
import "config/localForage";
import { StrictMode } from "react";
import { render } from "react-dom";
import { RouterProvider } from "react-router-dom";


render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
  document.getElementById("root"),
);