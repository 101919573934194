import cn from "classnames";
import { roundMomentTime } from "components/registration/forms/apiFn";
import dayjs from "dayjs";
import { dateSelector, placeSelector } from "features/AppContext";
import { useFromProxyActions } from "features/BookingFormProxy";
import { HallMode, useHallSchemaActions } from "features/HallSchema";
import { timelineSelectors } from "features/Timeline";
import { getSliderTime } from "features/Timeline/selectors";
import { useCurrentHallShiftsSchedule } from "features/api/hallschema-api";
import { useBlackoutZone } from "hooks/useBlackoutZone";
import { useIsTablet } from "hooks/useIsTablet";
import { defer } from "lodash";
import type { TableId } from "models/booking.model";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Location, useLocation, useNavigate } from "react-router-dom";
import type { PlaceTable } from "types/table";
import { BottomBar, Button } from "ui-kit";

import { useIntlUtils } from "../../../../hooks/useIntlUtils";
import { ETranslations } from "../../../../types/translates";
import styles from "./TableActions.module.scss";
import type { PlaceId } from "models/restaurant.model";

interface TableActionsProps {
  table: PlaceTable;
  isRegisterDisabled?: boolean;
  className?: string;
}

export const TableActions = memo(
  ({ table, isRegisterDisabled, className }: TableActionsProps) => {
    const { intl, getIntlJoinedParts, isRussianLocale, getIntlAddOf } =
      useIntlUtils();
    const sliderTime = useSelector(getSliderTime);
    const place = useSelector(placeSelector);
    const date = useSelector(dateSelector);
    const { switchMode, setActiveTables } = useHallSchemaActions();
    const { setTime } = useFromProxyActions();
    const isTablet = useIsTablet();
    const { setBooking, resetBooking } = useFromProxyActions();
    const location = useLocation() as Location<{ from: string } | null>;
    const navigate = useNavigate();
    const timestamp = useSelector(timelineSelectors.getTimestamp);
    const { data: optionsData } = useCurrentHallShiftsSchedule();

    const isBlackoutZone = useMemo(() => {
      return useBlackoutZone(timestamp, optionsData || []);
    }, [timestamp, optionsData]);

    const registrationHandle = () => {
      if (isRegisterDisabled) return;
      resetBooking();
      navigate("/create-booking", {
        state: {
          from: location.pathname,
        },
      });
      defer(() => {
        const time: String = roundMomentTime(sliderTime());
        setTime({ selectedTimeValue: time.substring(0, 5) });
        switchMode(
          isTablet ? HallMode.REGISTRATION_TABLET : HallMode.REGISTRATION_HALL,
        );
        setActiveTables({ activeTables: [table.table_id] });
        table &&
          setBooking({
            booking: {
              places: [
                {
                  table_id: table.table_id as TableId,
                  table_name: table.number.toString(),
                  hall_id: place as PlaceId,
                  place_id: place as PlaceId,
                },
              ],
            },
            client: null,
          });
      });
    };
    const checkIsToday = useCallback(
      () => dayjs.tz().isSame(date, "day"),
      [date],
    );

    const [isToday, setIsToday] = useState(checkIsToday);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsToday(checkIsToday);
      }, 1e3);
      return () => clearInterval(interval);
    }, [checkIsToday, setIsToday]);

    const createBooking = useCallback(() => {
      navigate("/create-booking", {
        state: {
          from: location.pathname,
        },
      });
      switchMode(isTablet ? HallMode.BOOKING_TABLET : HallMode.BOOKING_GUEST);
      table &&
        setBooking({
          booking: {
            places: [
              {
                table_id: table.table_id as TableId,
                table_name: table.number.toString(),
                hall_id: place as PlaceId,
                place_id: place as PlaceId,
              },
            ],
          },
          client: null,
        });
    }, [table, location]);

    return (
      <BottomBar className={cn(styles.container, className)}>
        <BottomBar.Part className={styles.buttons} placement="left">
          {Boolean(isToday) && !isBlackoutZone && (
            <Button
              disabled={isRegisterDisabled}
              variant="primary"
              onClick={registrationHandle}
            >
              {intl.formatMessage({ id: ETranslations.PLACING_BY_FACT })}
            </Button>
          )}

          <Button variant="primary" onClick={createBooking}>
            {isRussianLocale
              ? getIntlJoinedParts([
                  ETranslations.BASE_CREATE,
                  ETranslations.PLURAL_BOOKING,
                ])
              : intl.formatMessage({ id: ETranslations.CREATION_RESERVATION })}
          </Button>
        </BottomBar.Part>
      </BottomBar>
    );
  },
);
