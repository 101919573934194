import { TableId } from "models/booking.model";
import { ClientId } from "models/client.model";
import { Place, PlaceId, RestaurantId } from "models/restaurant.model";
import { ShiftId } from "models/shift.model";
import { SourceId } from "models/source.model";
import { Tag } from "models/tags.model";
import type { RequiredBy } from "types/commons";
import { z } from "zod";

export type StepNumber = 1 | 2 | 3 | 4 | 5;

export type StepValidationState = Record<
  `step${StepNumber}`,
  boolean | undefined
>;

export const BookingTime = z
  .string()
  .time()
  .refine((val): val is `${number}:${number}:${number}` => true);
export type BookingTime = z.infer<typeof BookingTime>;

const Datetime = z
  .string()
  .refine((val) => /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(val));

const ClientInfo = z.object({
  clientId: ClientId,
  phone: z.string().optional(),
  surname: z.string().optional(),
  name: z.string().optional(),
  middleName: z.string().optional(),
  contactType: z.literal("CLIENT"),
});
export type ClientInfo = z.infer<typeof ClientInfo>;

const ContactInfo = z.object({
  clientId: z.undefined(),
  phone: z.string().optional(),
  surname: z.string().optional(),
  name: z.string().optional(),
  middleName: z.string().optional(),
  contactType: z.literal("CONTACT"),
});
export type ContactInfo = z.infer<typeof ContactInfo>;

const Client = z.discriminatedUnion("contactType", [ClientInfo, ContactInfo]);
export type Client = z.infer<typeof Client>;

export const TableDetails = z.object({
  tableId: TableId,
  placeId: PlaceId,
  tableName: z.string(),
});
export type TableDetails = z.infer<typeof TableDetails>;

export const ShiftData = z.object({
  shiftId: ShiftId,
  shiftName: z.string(),
  startDatetime: Datetime,
  endDatetime: Datetime,
  timeInterval: z.number(),
});
export type ShiftData = z.infer<typeof ShiftData>;

const ShiftDetails = z.object({
  type: z.enum(["single", "multi"]),
  defaultValue: ShiftData,
  details: ShiftData.array(),
});
export type ShiftDetails = z.infer<typeof ShiftDetails>;

export const DepositStatus = z.discriminatedUnion("useDeposit", [
  z.object({
    useDeposit: z.literal(true),
    depositAmount: z.number().positive(),
    depositMade: z.boolean(),
  }),
  z.object({
    useDeposit: z.literal(false),
    depositAmount: z.literal(0).optional(),
    depositMade: z.literal(false).optional(),
  }),
]);
export type DepositStatus = z.infer<typeof DepositStatus>;

export const RestaurantInfo = z.object({
  id: RestaurantId,
  name: z.string(),
  places: Place.array(),
  placeById: z.record(
    z.union([
      PlaceId,
      z.string().refine((val): val is `${PlaceId}` => Number(val) > 0),
    ]),
    Place,
  ),
});
export type RestaurantInfo = z.infer<typeof RestaurantInfo>;

export const RestaurantsData = z.object({
  restaurants: RestaurantInfo.array().nonempty(),
  restaurantById: z.record(
    z.union([
      RestaurantId,
      z.string().refine((val): val is `${RestaurantId}` => Number(val) > 0),
    ]),
    RestaurantInfo,
  ),
});
export type RestaurantsData = z.infer<typeof RestaurantsData>;

export const BookingData = z.object({
  bookingDate: z.string().date(),
  bookingTime: BookingTime,
  persons: z.number().int().min(1),
  visitDuration: z.number().int(),
  shift: ShiftData,
  client: Client,
  tables: TableDetails.array(),
  tags: Tag.array().optional(),
  comment: z.string().optional(),
  deposit: DepositStatus,
  source: z
    .object({
      id: SourceId,
      name: z.string(),
    })
    .optional(),
  userId: z.number(),
});
export type BookingData = RequiredBy<
  z.infer<typeof BookingData>,
  | "bookingDate"
  | "bookingTime"
  | "shift"
  | "persons"
  | "visitDuration"
  | "userId"
>;

const LoaderData = z.object({
  defaultBookingDate: z.string().date(),
  defaultBookingTime: BookingTime,
  defaultRestaurant: RestaurantInfo,
  cachedRestaurant: RestaurantInfo.optional(),
  restaurantsData: RestaurantsData,
  defaultShiftDetails: ShiftDetails,
  cachedBookingData: BookingData.partial().optional(),
  userId: z.number(),
});
export type LoaderData = z.infer<typeof LoaderData>;
