import { TableDetails } from "containers/CreateBookingForm/model";
import { z } from "zod";

import { deserializeTableData } from "../utils";
import { PlaceId } from "models/restaurant.model";

export const TableFormSchema = z.object({
  placeId: z.union([
    z.string().transform(Number).pipe(PlaceId),
    z.literal("all"),
  ]),

  tables: z.union([
    TableDetails.array().nonempty(),
    z
      .string()
      .transform((val) => deserializeTableData(val))
      .array()
      .nonempty(),
  ]),
});
export type TableFormSchema = z.infer<typeof TableFormSchema>;
export type TableFormSchemaInput = z.input<typeof TableFormSchema>;
