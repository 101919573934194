import { Plugin, Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { Plugins } from "@technolab/hse";
import type { HallSlotsDTO } from "features/api/hallschema-api";
import type { BookingTable } from "types/table";



import { useTablesSlots } from "../../hall-scheme/redux/HallSchemaV2/NewScheme/utils";
import { BookingTableTemplate } from "./BookingTableTemplate";


const { Sector } = Plugins;

const PLUGIN_NAME = "HOS_BOOKING_TABLE";

type Props = {
  hallInfo: HallSlotsDTO[];
  sectors: BookingTable[][];
};

export function BookingTable({ hallInfo, sectors }: Props) {
  const slots = useTablesSlots(hallInfo);

  return (
    <Plugin name={PLUGIN_NAME}>
      <Template name={Sector.TEMPLATES.root}>
        <TemplatePlaceholder />
        {sectors.map((tables) => (
          <g
            className="hall_sector"
            key={`${tables[0].tableId}-${tables[tables.length - 1].tableId}`}
          >
            {tables.map((table) => (
              <BookingTableTemplate
                key={table.tableId}
                table={table}
                tableSlots={slots[table.tableId]}
              />
            ))}
          </g>
        ))}
      </Template>
    </Plugin>
  );
}