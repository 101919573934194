import type { PlaceTable } from "types/table";
import { z } from "zod";

import { Table } from "./common";
import { AppointUser } from "./user.model";

export const RestaurantId = z.number().int().brand("RestaurantId");
export type RestaurantId = z.infer<typeof RestaurantId>;
export const PlaceId = z.number().int().brand("PlaceId");
export type PlaceId = z.infer<typeof PlaceId>;

export class Schedule {
  constructor(
    public duration: number,
    public time_start: string,
    public week_day: number,
  ) {}
}

export const Place = z.object({
  id: PlaceId,
  name: z.string(),
  weight: z.number(),
  restaurant_id: RestaurantId,
  fetcher_id: z.number().nullable(),
  sector_id: z.number().nullable(),
});
export type Place = z.infer<typeof Place>;

export class Restaurant {
  constructor(
    public restaurant_id: RestaurantId,
    public timezone: string,
    public restaurant_name: string,
    public places: Place[],
    public phone?: string,
  ) {}
}

export interface RestaurantExtended extends Restaurant {
  value: number;
  label: string;
}

export class RestaurantWithHostess {
  constructor(
    public id: number,
    public name: string,
    public hostessList: AppointUser[],
  ) {}
}

export interface AppointRestaurant {
  restaurant: {
    name: string;
    phone: string | null;
    max_guests: number;
    id?: number;
  };
  user: {
    login: string;
    password?: string;
  };
}
