import cn from "classnames";
import type { ShiftDetails } from "containers/CreateBookingForm/model";
import dayjs from "dayjs";
import type { ReactNode } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { RadioGroup } from "ui-kit";

import { type ButtonParam, serializeShiftData } from "../../utils";
import { MultiShiftRadioButtons } from "./MultiShiftRadioButtons";
import styles from "./ShiftRadioGroup.module.scss";

export const ShiftRadioGroup = ({
  name,
  shiftDetails,
  children,
  error,
  onChange,
}: {
  name: string;
  shiftDetails: ShiftDetails;
  children: (buttonParam: ButtonParam) => ReactNode;
  error?: string | string[];
  onChange?: (value: string) => void;
}) => {
  const { formatMessage } = useIntl();
  const now = dayjs.tz();
  return (
    <RadioGroup.Root
      variant="bordered"
      groupName={name}
      className={cn(
        shiftDetails.type === "multi" && styles.radioGroupContainer,
      )}
      label={formatMessage({ id: ETranslations.AVAILABLE_TIME })}
      required
      onChange={onChange}
      error={error}
    >
      {shiftDetails.type === "multi" ? (
        <MultiShiftRadioButtons>
          {shiftDetails.details.map((shift) =>
            children({
              text: shift.shiftName,
              value: serializeShiftData(shift),
            }),
          )}
        </MultiShiftRadioButtons>
      ) : (
        <RadioGroup.Content>
          {shiftDetails.details.map((shift) => {
            const startDatetime = dayjs(shift.startDatetime);
            const endDateTime = dayjs.tz(shift.endDatetime);
            return children({
              text: `${startDatetime.format("HH:mm")}-${endDateTime.format("HH:mm")}`,
              value: serializeShiftData(shift),
              disabled: now.isAfter(endDateTime),
            });
          })}
        </RadioGroup.Content>
      )}
    </RadioGroup.Root>
  );
};
