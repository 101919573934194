import {
  ConfirmMessage,
  ConfirmMessageActions,
  ConfirmMessageHeader,
  ConfirmMessageText,
} from "components/ConfirmModal";
import { FormFooter } from "components/MultiStepForm";
import { type MouseEvent, type MouseEventHandler, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ETranslations } from "types/translates";
import {
  Button,
  type ButtonProps,
  ModalDialog,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogForm,
} from "ui-kit";

import { useCreateBooking, useCreateBookingFormContext } from "../../Provider";
import type { BookingData } from "../../model";

const CreateBookingButton = ({
  onBeforeCreateBooking,
  ...props
}: {
  onBeforeCreateBooking?: (
    e: MouseEvent<HTMLButtonElement>,
  ) =>
    | Promise<
        | { success: true; data: BookingData }
        | { success: false; data: undefined }
      >
    | { success: true; data: BookingData }
    | { success: false; data: undefined };
} & Partial<ButtonProps>) => {
  const { bookingData, restaurant } = useCreateBookingFormContext();
  const overbookingDialogRef = useRef<HTMLDialogElement>(null);
  const { formatMessage } = useIntl();
  const { createBooking, isLoading } = useCreateBooking(
    overbookingDialogRef.current?.showModal,
  );

  const handleCreate: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onBeforeCreateBooking) {
      Promise.resolve(onBeforeCreateBooking(e)).then(({ success, data }) =>
        success
          ? createBooking({ formData: data, restaurantId: restaurant.id })
          : undefined,
      );
    } else {
      createBooking({ formData: bookingData, restaurantId: restaurant.id });
    }
  };
  return (
    <>
      <Button variant="primary" onClick={handleCreate} {...props}>
        {formatMessage({ id: ETranslations.BASE_CREATE })}
      </Button>
      <ModalDialog ref={overbookingDialogRef}>
        <ModalDialogContent>
          <ModalDialogCloseButton />
          <ModalDialogForm
            onSubmit={(e) => {
              e.preventDefault();
              createBooking({
                formData: bookingData,
                restaurantId: restaurant.id,
                isOverbooking: true,
              });
            }}
          >
            <ConfirmMessage>
              <ConfirmMessageHeader>
                {formatMessage({
                  id: ETranslations.BOOKING_CROSSING,
                })}
              </ConfirmMessageHeader>
              <ConfirmMessageText>
                {formatMessage({ id: ETranslations.BOOKING_OVERLAP })}
              </ConfirmMessageText>
              <ConfirmMessageActions>
                <Button variant="dangerous" type="submit" disabled={isLoading}>
                  {formatMessage({
                    id: ETranslations.BASE_CONFIRM,
                  })}
                </Button>
                <ModalDialogCloseButton
                  isCancelButton
                  variant="secondary"
                  disabled={isLoading}
                >
                  {formatMessage({ id: ETranslations.BASE_CANCEL })}
                </ModalDialogCloseButton>
              </ConfirmMessageActions>
            </ConfirmMessage>
          </ModalDialogForm>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};

export const CreateBookingFormFooter = ({
  currentStep,
  onBeforeNavigate,
  ...props
}: {
  currentStep: 1 | 2 | 3 | 4 | 5;
  onBeforeNavigate?: (
    e: MouseEvent<HTMLButtonElement>,
  ) =>
    | Promise<
        | { success: true; data: BookingData }
        | { success: false; data: undefined }
      >
    | { success: true; data: BookingData }
    | { success: false; data: undefined };
} & Partial<ButtonProps>) => {
  const { formatMessage } = useIntl();
  const { findNextInvalidStep } = useCreateBookingFormContext();
  const navigate = useNavigate();
  const nextInvalidStep = findNextInvalidStep(currentStep);
  const to = nextInvalidStep && `/create-booking-new/step-${nextInvalidStep}`;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onBeforeNavigate) {
      Promise.resolve(onBeforeNavigate(e)).then(({ success }) => {
        success && to && navigate(to);
      });
    } else {
      to && navigate(to);
    }
  };

  return (
    <FormFooter>
      {nextInvalidStep ? (
        <Button variant="primary" onClick={handleClick} {...props}>
          {formatMessage({ id: ETranslations.CONTINUE })}
        </Button>
      ) : (
        <CreateBookingButton
          onBeforeCreateBooking={onBeforeNavigate}
          {...props}
        />
      )}
    </FormFooter>
  );
};
