import { ETranslations } from "types/translates";
import { z } from "zod";

import { MAX_NAME_LENGTH } from "../constants";

const NameField = z
  .string()
  .trim()
  .regex(
    /^[\p{sc=Cyrillic}|\p{sc=Latin}]+$/u,
    ETranslations.ONLY_LATIN_CYRILLIC,
  )
  .max(MAX_NAME_LENGTH, ETranslations.FIELD_TOO_LONG);

export const CreateClientFormSchema = z.object({
  surname: z.union([z.string().trim().pipe(z.literal("")), NameField]),
  name: NameField.min(1, ETranslations.FIELD_CANT_BE_EMPTY),
  middleName: z.union([z.string().trim().pipe(z.literal("")), NameField]),
  phone: z
    .string()
    .trim()
    .regex(/^\+[1-9]\d{1,14}$/, ETranslations.FIELD_INVALID)
    .min(4, ETranslations.FIELD_CANT_BE_EMPTY),
});
export type CreateClientFormSchema = z.infer<typeof CreateClientFormSchema>;

export const RegisterContactFormSchema = z.object({
  surname: z.union([z.string().trim().pipe(z.literal("")), NameField]),
  name: z.union([z.string().trim().pipe(z.literal("")), NameField]),
  middleName: z.union([z.string().trim().pipe(z.literal("")), NameField]),
});

export type RegisterContactFormSchema = z.infer<
  typeof RegisterContactFormSchema
>;
