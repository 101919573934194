import { type BookingTime, ShiftData } from "../model";

export type ButtonParam = {
  text: string;
  value: string;
  disabled?: boolean;
};

export function serializeShiftData(data: ShiftData): string {
  // Проверяем данные перед сериализацией
  ShiftData.parse(data);

  // Сериализуем в строку JSON
  return JSON.stringify(data, Object.keys(data).sort());
}

export function deserializeShiftData(serializedData: string): ShiftData {
  try {
    // Парсим строку JSON
    const parsedData = JSON.parse(serializedData);

    // Проверяем структуру данных после десериализации
    ShiftData.parse(parsedData);

    return parsedData;
  } catch {
    throw new Error("Can't deserialize ShiftData");
  }
}

export const getHour = (time: BookingTime | (string & {})) =>
  Number(time.slice(0, 2));
export const getMinutes = (time: BookingTime | (string & {})) =>
  Number(time.slice(3, 5));

export const isTimeBetween = (time1: number, target: number, time2: number) => {
  return (time1 <= target && time2 > target) || undefined;
};
