import cn from "classnames";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import styles from "./FormStepper.module.scss";

export type StepInfo = {
  title: string;
  description?: ReactNode;
  valid?: boolean;
};

const FormStepper = ({
  stepsInfo,
  errorText,
}: {
  // valid имеет три состояния: true = валидный, false = инвалидный, undefined = не проверялся
  stepsInfo: {
    title: string;
    description?: ReactNode;
    valid?: boolean;
  }[];
  errorText: string;
}) => {
  return (
    <nav className={styles.stepList}>
      <ul>
        {stepsInfo.map((info, index) => (
          <li
            key={info.title}
            className={cn(
              styles.stepItem,
              info.valid
                ? styles.valid
                : info.valid === false && styles.invalid,
            )}
          >
            <NavLink
              to={`step-${index + 1}`}
              className={({ isActive, isPending }) =>
                cn(styles.navLink, isActive && styles.active)
              }
            >
              <strong className={styles.stepTitle}>{info.title}</strong>
              {info.description && (
                <span className={styles.stepDescription}>
                  {info.valid !== false ? info.description : errorText}
                </span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

FormStepper.displayName = "FormStepper";
export { FormStepper };
