import type {
  BookingData,
  RestaurantInfo,
  RestaurantsData,
  StepNumber,
  StepValidationState,
} from "containers/CreateBookingForm/model";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { invariant } from "utils";

type CreateBookingFormContext = {
  bookingData: BookingData;
  cachedBookingData?: Partial<BookingData>;
  stepValidationState: StepValidationState;
  restaurant: RestaurantInfo;
  cachedRestaurant?: RestaurantInfo;
  restaurantsData: RestaurantsData;
  findNextInvalidStep: (
    currentStep: StepNumber,
    newStepValidationState?: Partial<StepValidationState>,
  ) => StepNumber | undefined;
  updateBookingData: (newValues: Partial<BookingData>) => BookingData;
  updateStepValidationState: (
    newStepValidationState: Partial<StepValidationState>,
  ) => void;
  changeRestaurant: (newRestaurant: RestaurantInfo) => void;
};

export const createBookingFormContext = createContext<CreateBookingFormContext>(
  null!,
);

export const useCreateBookingFormContext = () => {
  const value = useContext(createBookingFormContext);
  return (value ||
    invariant(
      value,
      "CreateBookingForm context not passed, please wrap your components with <CreateBookingFormProvider />",
    )) as CreateBookingFormContext;
};

export const useCreateBookingFormContextSelector = <Selected>(
  selector: (context: CreateBookingFormContext) => Selected,
) =>
  useContextSelector<CreateBookingFormContext, Selected>(
    createBookingFormContext,
    selector,
  );
