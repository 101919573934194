import type { TableId } from "models/booking.model";
import { IResponse } from "models/common";
import type { PlaceId, RestaurantId } from "models/restaurant.model";
import type { ShiftId } from "models/shift.model";
import {
  type Seat,
  SeatSlotDTO,
  ShiftSlotDTO,
  TimeSlotDTO,
} from "models/slot.model";

import { api } from "./api";

export const slotsApi = api
  .enhanceEndpoints({
    addTagTypes: ["Slot", "SeatSlot", "ShiftSlot", "TimeSlot"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchShiftSlots: build.query<
        ShiftSlotDTO[],
        { restaurantId: RestaurantId; date: string }
      >({
        query: ({ restaurantId, date }) => ({
          url: `/v2/slots/${restaurantId}/shifts`,
          params: { date },
        }),
        transformResponse: (response: IResponse<ShiftSlotDTO[]>) => {
          const result = ShiftSlotDTO.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (_, __, args) => [
          {
            type: "ShiftSlot",
            id: `${args.restaurantId}-${args.date}`,
          },
        ],
        keepUnusedDataFor: 10,
      }),
      fetchSeatSlots: build.query<
        SeatSlotDTO & {
          availabilityById: Record<PlaceId, Record<TableId, Seat>>;
        },
        {
          restaurantId: RestaurantId;
          date: string;
          shiftId: ShiftId;
          time: string;
          visitDuration: number;
          persons: number;
        }
      >({
        query: (params) => ({
          url: `/v2/slots/${params.restaurantId}/seats`,
          params: {
            shift_id: params.shiftId,
            date: params.date,
            time: params.time,
            visit_duration: params.visitDuration,
            persons: params.persons,
          },
        }),
        transformResponse: (response: IResponse<SeatSlotDTO>) => {
          const result = SeatSlotDTO.safeParse(response.data);
          !result.success && console.log(result.error.format());
          const data = result.success ? result.data : response.data;
          const availabilityById = data.availability.reduce(
            (acc, hall) => {
              hall.seats.forEach((table) =>
                acc[hall.hall_id]
                  ? (acc[hall.hall_id][table.table_id] = table)
                  : (acc[hall.hall_id] = { [table.table_id]: table }),
              );
              return acc;
            },
            {} as Record<PlaceId, Record<TableId, Seat>>,
          );
          return { ...data, availabilityById };
        },
        providesTags: (_, __, args) => [
          {
            type: "SeatSlot",
            id: JSON.stringify(args),
          },
        ],
        keepUnusedDataFor: 10,
      }),
      fetchTimeSlots: build.query<
        TimeSlotDTO[],
        {
          restaurantId: RestaurantId;
          date: string;
          shiftId: ShiftId;
          visitDuration: number;
          persons: number;
        }
      >({
        query: (params) => ({
          url: `/v2/slots/${params.restaurantId}/times`,
          params: {
            shift_id: params.shiftId,
            date: params.date,
            visit_duration: params.visitDuration,
            visitors: params.persons,
          },
        }),
        transformResponse: (response: IResponse<TimeSlotDTO[]>) => {
          const result = TimeSlotDTO.array().safeParse(response.data);
          !result.success && console.log(result.error.format());
          return result.success ? result.data : response.data;
        },
        providesTags: (_, __, args) => [
          {
            type: "TimeSlot",
            id: JSON.stringify(args),
          },
        ],
        keepUnusedDataFor: 10,
      }),
    }),
  });

export const {
  useFetchShiftSlotsQuery,
  useFetchSeatSlotsQuery,
  useFetchTimeSlotsQuery,
} = slotsApi;
