import styles from "./ShortUserInfo.module.scss";
import { config } from "config";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import type { TNullable } from "types/commons";
import { ETranslations } from "types/translates";
import { capitalizeString } from "utils";

interface Props {
  visits: TNullable<number>;
  canceled: TNullable<number>;
  noShow: TNullable<number>;
  avgSpend: TNullable<number>;
}

export const CountRow: FC<Props> = ({ visits, canceled, noShow, avgSpend }) => {
  const intl = useIntl();

  return (
    <div className={styles.countRow}>
      <div className={styles.item}>
        <span className={styles.title}>
          {capitalizeString(
            intl.formatMessage({ id: ETranslations.PLURAL_VISITS_NOM }),
          )}
        </span>
        <span className={styles.description}>
          {visits ?? intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.CANCELS })}
        </span>
        <span className={styles.description}>
          {canceled ?? intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({
            id: ETranslations.DID_NOT_COME,
          })}
        </span>
        <span className={styles.description}>
          {noShow ?? intl.formatMessage({ id: ETranslations.BASE_NO })}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>
          {intl.formatMessage({ id: ETranslations.GUEST_AVG_PER_VISIT })}
        </span>
        <span
          className={styles.description}
        >{`${Number(avgSpend)} ${config.currency}`}</span>
      </div>
    </div>
  );
};
