import cn from "classnames";
import {
  type ReactNode,
  TextareaHTMLAttributes,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { invariant } from "utils";

import { Labeled } from "../Labeled";
import styles from "./Textarea.module.scss";
import {
  TextAreaContextProvider,
  useTextAreaContext,
  useTextAreaContextSelector,
} from "./context";

// Функция для вычисления процента для управления цветом, начинаем изменять цвет с colorChangeThreshold % от maxLength
const getAdjustedColorPercentage = (
  textLength: number,
  maxLength: number,
  colorChangeThreshold: number,
) =>
  (
    Math.max(
      Math.min((textLength * 100) / maxLength, 100) - colorChangeThreshold,
      0,
    ) *
    (100 / (100 - colorChangeThreshold))
  ).toFixed(2);

type BaseTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaProps extends BaseTextareaProps {
  label?: string;
}

export function Textarea({ label, className, ...props }: TextareaProps) {
  return (
    <Labeled className={className} label={label} required={props.required}>
      <textarea {...props} className={styles.textarea} />
    </Labeled>
  );
}

const AutoResizeTextarea = ({
  defaultValue,
  maxLength,
  className,
  label,
  ...props
}: Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "maxLength" | "defaultValue"
> & { maxLength: number; label?: ReactNode; defaultValue?: string }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    const textareaRefElement = textareaRef.current;
    textareaRefElement &&
      (textareaRefElement.style.height =
        textareaRefElement.scrollHeight + "px");
  }, []);

  return (
    <label
      className={styles.container}
      onChange={(e) => {
        const textareaElement = e.target;
        const dataElement = e.currentTarget.children[1];
        // Проверка на то, что textareaElement действительно HTMLTextAreaElement, а dataElement действительно HTMLDataElement
        invariant(
          textareaElement instanceof HTMLTextAreaElement &&
            dataElement instanceof HTMLDataElement,
          "Wrong AutoResizeTextarea structure",
        );
        // Устанавливаем высоту на основе содержимого
        const textareaHeight = textareaElement.offsetHeight;
        const textareaIdealHeight = textareaElement.scrollHeight;
        if (textareaHeight > textareaIdealHeight) {
          textareaElement.style.height = "auto";
        }
        textareaElement.style.height = textareaElement.scrollHeight + "px";
        // Устанавливаем значение каунтера равным длине текста в поле
        dataElement.value = String(textareaElement.value.length);
        // Если есть ограничение по максимальной длине, то управляем цветом каунтера (соотношение текущая длина текста / максимальная)
        if (maxLength) {
          dataElement.style.setProperty(
            "--text-length-percentage",
            `${getAdjustedColorPercentage(+dataElement.value, maxLength, 70)}%`,
          );
        }
      }}
    >
      {label}
      <textarea
        className={cn(styles.textarea, styles.autoResize, className)}
        ref={textareaRef}
        defaultValue={defaultValue}
        maxLength={maxLength}
        {...props}
      />
      <data
        style={{
          "--text-length-percentage": `${getAdjustedColorPercentage(defaultValue?.length || 0, maxLength, 70)}%`,
        }}
        value={defaultValue?.length || 0}
        className={styles.counter}
        data-max={maxLength}
      />
    </label>
  );
};

AutoResizeTextarea.displayName = "AutoResizeTextarea";

export { AutoResizeTextarea };

const AutoResizeTextareaRoot = ({
  label,
  name,
  maxLength,
  defaultValue,
  className,
  children,
}: {
  label?: ReactNode;
  name?: string;
  maxLength: number;
  defaultValue?: string;
  className?: string;
  children: ReactNode;
}) => {
  const [counter, setCounter] = useState(defaultValue?.length || 0);

  const value = {
    name,
    defaultValue,
    maxLength,
    counter,
    setCounter,
  };

  return (
    <label className={cn(styles.container, className)}>
      {label}
      <TextAreaContextProvider value={value}>
        {children}
      </TextAreaContextProvider>
    </label>
  );
};

const AutoResizeTextareaInput = forwardRef<
  HTMLTextAreaElement,
  Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    "maxLength" | "defaultValue" | "name"
  >
>(({ className, onChange, ...props }, ref) => {
  const { name, maxLength, defaultValue, setCounter } =
    useTextAreaContextSelector((c) => ({
      name: c.name,
      maxLength: c.maxLength,
      defaultValue: c.defaultValue,
      setCounter: c.setCounter,
    }));

  const internalRef = useRef<HTMLTextAreaElement | null>(null);

  useLayoutEffect(() => {
    const textareaRefElement =
      (ref as React.RefObject<HTMLTextAreaElement>)?.current ??
      internalRef.current;
    if (textareaRefElement) {
      textareaRefElement.style.height = textareaRefElement.scrollHeight + "px";
    }
  }, []);

  return (
    <textarea
      className={cn(styles.textarea, styles.autoResize, className)}
      name={name}
      ref={(node) => {
        if (typeof ref === "function") {
          ref(node);
        } else if (ref) {
          (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current =
            node;
        }
        internalRef.current = node;
      }}
      defaultValue={defaultValue}
      maxLength={maxLength}
      onChange={(e) => {
        const textareaElement = e.currentTarget;
        const textareaHeight = textareaElement.offsetHeight;
        const textareaIdealHeight = textareaElement.scrollHeight;

        if (textareaHeight > textareaIdealHeight) {
          textareaElement.style.height = "auto";
        }
        textareaElement.style.height = textareaElement.scrollHeight + "px";
        setCounter(textareaElement.value.length);
        onChange?.(e);
      }}
      {...props}
    />
  );
});

AutoResizeTextareaInput.displayName = "AutoResizeTextareaInput";

const AutoResizeTextareaCounter = ({ className }: { className?: string }) => {
  const { maxLength, counter } = useTextAreaContext();
  return (
    <data
      style={{
        "--text-length-percentage": `${getAdjustedColorPercentage(counter, maxLength, 70)}%`,
      }}
      value={maxLength}
      className={cn(styles.counter, className)}
    >
      {counter}
    </data>
  );
};

export const TextareaComposite = {
  Root: AutoResizeTextareaRoot,
  Input: AutoResizeTextareaInput,
  Counter: AutoResizeTextareaCounter,
};
