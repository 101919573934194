import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type { ClientInfo } from "containers/CreateBookingForm/model";
import { useCreateClientMutation } from "features/api/client-api";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { type Value as E164Number } from "react-phone-number-input";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";
import { ETranslations } from "types/translates";
import { FieldError, Input } from "ui-kit";
import { commonFormErrorMap } from "utils";

import { MAX_NAME_LENGTH } from "../constants";
import { CreateClientFormSchema } from "../model/schema";
import styles from "./ClientForm.module.scss";

export const CreateClientForm = ({
  formId,
  setIsValid,
  onSubmit,
}: {
  formId?: string;
  setIsValid: (isValid: boolean) => void;
  onSubmit: (client: ClientInfo) => void;
}) => {
  const { formatMessage } = useIntl();

  const { updateStepValidationState } = useCreateBookingFormContext();
  const [createGuest, { isLoading: isCreateGuestLoading }] =
    useCreateClientMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setError,
  } = useForm<CreateClientFormSchema>({
    resolver: zodResolver(CreateClientFormSchema, {
      errorMap: commonFormErrorMap,
    }),
    disabled: isCreateGuestLoading,
    mode: "onChange",
  });

  const handleFormSubmit = async ({
    name,
    middleName,
    surname,
    phone,
  }: CreateClientFormSchema) => {
    try {
      const response = await createGuest({
        name,
        surname,
        middle_name: middleName,
        phone,
      });

      if (response && "data" in response) {
        Notification.success(ETranslations.CLIENT_SUCCESSFULLY_CREATED);
        const client: ClientInfo = {
          clientId: response.data.data.client_id,
          phone,
          surname: surname || "",
          name: name || "",
          middleName: middleName || "",
          contactType: "CLIENT",
        };
        onSubmit(client);
      } else {
        Notification.error(ETranslations.CLIENT_CREATION_FAILED);
        throw response.error;
      }
    } catch (error) {
      updateStepValidationState({ step3: false });

      const err = error as ErrorResponse["error"];

      if (err?.data?.errorCode === 10000) {
        setError(
          "phone",
          { message: ETranslations.PHONE_ALREADY_REGISTERED },
          { shouldFocus: true },
        );
      } else {
        setError("root", {
          message: ETranslations.ERROR_SOMETHING_WENT_WRONG,
        });
      }
    }
  };

  return (
    <form
      id={formId}
      className={styles.clientForm}
      onChange={() => {
        setIsValid(isValid);
      }}
      onBlur={() => {
        setIsValid(isValid);
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Input
        label={formatMessage({ id: ETranslations.NAME })}
        autoComplete="off"
        required
        max={MAX_NAME_LENGTH}
        {...register("name", { required: true })}
        error={errors.name?.message}
      />
      <Input
        label={formatMessage({ id: ETranslations.LAST_NAME })}
        placeholder={formatMessage(
          { id: ETranslations.BASE_ENTER_VALUE },
          { value: formatMessage({ id: ETranslations.LAST_NAME_ALT }) },
        )}
        autoComplete="off"
        max={MAX_NAME_LENGTH}
        {...register("surname", { required: false })}
        error={errors.surname?.message}
      />

      <Input
        label={formatMessage({ id: ETranslations.MIDDLE_NAME })}
        autoComplete="off"
        max={MAX_NAME_LENGTH}
        {...register("middleName", { required: false })}
        error={errors.middleName?.message}
      />
      <Controller
        control={control}
        name="phone"
        render={({
          field: { onChange, value, disabled, name },
          fieldState: { invalid },
        }) => (
          <Input.Phone
            name={name}
            className={styles.phone}
            disabled={disabled}
            label={formatMessage({ id: ETranslations.PHONE })}
            autoComplete="off"
            required
            value={value as E164Number}
            onChange={(v) => {
              onChange(v || "");
              invalid && setIsValid(isValid);
            }}
            error={errors.phone?.message}
          />
        )}
      />
      {errors.root?.message && (
        <FieldError className={styles.formError} error={errors.root.message} />
      )}
    </form>
  );
};
