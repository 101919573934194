import { config } from "config";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { Button, Input } from "ui-kit";

import type { ExtraFormSchema } from "../model/schema";
import styles from "./DepositField.module.scss";

export const DepositField = ({
  control,
}: {
  control: Control<ExtraFormSchema>;
}) => {
  const { field: useDeposit } = useController({
    control,
    name: "deposit.useDeposit",
  });
  const { field: depositAmount } = useController({
    control,
    name: "deposit.depositAmount",
    disabled: !useDeposit.value,
  });
  const { field: depositMade } = useController({
    control,
    name: "deposit.depositMade",
    disabled: !useDeposit.value,
  });

  const { formatMessage } = useIntl();
  return (
    <fieldset className={styles.depositField}>
      <legend>{formatMessage({ id: ETranslations.DEPOSIT })}</legend>
      <Button
        className={styles.useDeposit}
        type="button"
        variant={useDeposit.value ? "primary" : "thin"}
        onClick={() => {
          useDeposit.onChange(!useDeposit.value);
          depositAmount.onChange(0);
          depositMade.onChange(false);
        }}
      >
        {formatMessage({
          id: useDeposit.value
            ? ETranslations.WITH_DEPOSIT
            : ETranslations.NO_DEPOSIT,
        })}
      </Button>
      <Input
        type="number"
        inputMode="numeric"
        suffix={config.currency}
        {...depositAmount}
        onChange={(e) =>
          depositAmount.onChange(parseInt(e.currentTarget.value, 10))
        }
      />
      <input
        type="checkbox"
        id={depositMade.name}
        hidden
        readOnly
        checked={depositMade.value}
        onChange={depositMade.onChange}
        onBlur={depositMade.onBlur}
        ref={depositMade.ref}
        name={depositMade.name}
        disabled={depositMade.disabled}
      />
      <label className={styles.checkbox} htmlFor={depositMade.name}>
        {formatMessage({ id: ETranslations.DEPOSIT_MADE })}
      </label>
    </fieldset>
  );
};
