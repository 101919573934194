import { useFetchSeatSlotsQuery } from "features/api/slots";
import type { PlaceId, RestaurantId } from "models/restaurant.model";
import type { ShiftId } from "models/shift.model";
import type { Seat } from "models/slot.model";
import { type Control, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { RadioGroup, Spinner } from "ui-kit";

import type { TableFormSchemaInput } from "../model/schema";
import styles from "./TableRadioGroup.module.scss";

export const TableRadioGroup = ({
  name,
  control,
  restaurantId,
  date,
  shiftId,
  time,
  visitDuration,
  persons,
  children,
}: {
  name: string;
  control: Control<TableFormSchemaInput>;

  restaurantId: RestaurantId;
  date: string;
  shiftId: ShiftId;
  time: string;
  visitDuration: number;
  persons: number;
  children: (seat: Seat, placeId: PlaceId) => JSX.Element;
}) => {
  const { data, isLoading } = useFetchSeatSlotsQuery({
    restaurantId,
    date,
    persons,
    shiftId,
    time,
    visitDuration,
  });
  const { formatMessage } = useIntl();
  const placeId = useWatch({ control, name: "placeId" });
  const isAllPlacesActive = placeId === "all";
  const halls = isAllPlacesActive
    ? data?.availability
    : data?.availability.filter((hall) => hall.hall_id === Number(placeId));
  return isLoading ? (
    <Spinner />
  ) : halls?.length ? (
    <RadioGroup.Root groupName={name} multiple>
      <RadioGroup.Content className={styles.halls}>
        {halls.map((hall) => (
          <article className={styles.hallGroup} key={hall.hall_id}>
            <h3 className={styles.hallTitle}>{hall.hall_name}</h3>
            {hall.seats
              .toSorted((a, b) => +a.table_number - +b.table_number)
              .map((seat) => children(seat, hall.hall_id))}
          </article>
        ))}
      </RadioGroup.Content>
    </RadioGroup.Root>
  ) : (
    <p className={styles.noTables}>
      {formatMessage({ id: ETranslations.NO_AVAILABLE_TABLES })}
    </p>
  );
};
