import { getMaskPhone, getShortName } from "common/helpers";
import type { Place, PlaceId } from "models/restaurant.model";
import type { IntlShape } from "react-intl";
import { ETranslations } from "types/translates";

import type { BookingTime, Client, TableDetails } from "./model";

const personTranslates = {
  one: ETranslations.PLURAL_GUEST,
  two: ETranslations.PLURAL_GUESTS_ALT,
  many: ETranslations.PLURAL_GUESTS,
};

const hoursTranslates = {
  one: ETranslations.PLURAL_HOUR,
  two: ETranslations.PLURAL_HOURS_ALT,
  many: ETranslations.PLURAL_HOURS,
};

export const getCountSuffix = (
  value: number,
  translates: { one: ETranslations; two: ETranslations; many: ETranslations },
) => {
  const allTranslates = {
    zero: translates.many,
    one: translates.one,
    two: translates.two,
    few: translates.many,
    many: translates.many,
    other: translates.many,
  };

  const lastTwoDigits = value % 100;
  const lastDigit = value % 10;

  if (
    (lastTwoDigits < 11 || lastTwoDigits > 19) &&
    lastDigit >= 2 &&
    lastDigit <= 4
  ) {
    return translates.two;
  }

  return allTranslates[new Intl.PluralRules("ru").select(value)];
};

export const getPersonsSuffix = (value: number) =>
  getCountSuffix(value, personTranslates);

export const getHoursSuffix = (value: number) =>
  getCountSuffix(value, hoursTranslates);

// форматирует данные времени и персон в строку вида 2 гостя, 22:30
export const formatTimeAndPersons = ({
  time,
  persons,
  formatMessage,
}: {
  time: BookingTime;
  persons: number;
  formatMessage: IntlShape["formatMessage"];
}) => {
  const personsString = `${persons} ${formatMessage({ id: getPersonsSuffix(persons) })}`;

  return `${personsString} ${time.slice(0, 5)}`;
};

// форматирует данные клиента в строку вида К.В. Константинопольский\n+7 (917) 210-33-31
export const formatClient = (client: Client) => {
  const shortName =
    (client.middleName || client.surname
      ? getShortName(client.name, client.middleName, client.surname)
      : client.name) || "N/A";
  const phone = client.phone ? getMaskPhone(client.phone) : "";

  return `${shortName}\n${phone}`;
};

// форматирует данные о столах в строку вида 3 зал: 18 стол, 27 стол
export const formatTables = ({
  tables,
  placeById,
  formatMessage,
}: {
  tables: TableDetails[];
  placeById: Partial<Record<PlaceId, Place>>;
  formatMessage: IntlShape["formatMessage"];
}) => {
  const placeInfoById = tables.reduce(
    (acc, table) => {
      const placeId = table.placeId;
      const tableString = `${table.tableName} ${formatMessage({ id: ETranslations.PLURAL_TABLE }).toLowerCase()}`;
      placeId in acc
        ? acc[placeId].tableNames.push(tableString)
        : (acc[placeId] = {
            placeName: placeById[placeId]?.name || "N/A",
            tableNames: new Array(tableString),
          });
      return acc;
    },
    {} as Record<
      PlaceId,
      {
        placeName: string;
        tableNames: string[];
      }
    >,
  );
  const formattedTables = Object.values(placeInfoById).reduce(
    (acc, placeInfo) =>
      acc + `${placeInfo.placeName}: ${placeInfo.tableNames.join(", ")}\n\n`,
    "",
  );
  return formattedTables;
};
