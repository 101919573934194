import { TableDetails } from "../model";

export function serializeTableData(data: TableDetails): string {
  // Проверяем данные перед сериализацией
  TableDetails.parse(data);
  // Сериализуем в строку JSON
  return JSON.stringify(data, Object.keys(data).sort());
}

export function deserializeTableData(serializedData: string): TableDetails {
  try {
    // Парсим строку JSON
    const parsedData = JSON.parse(serializedData);

    // Проверяем структуру данных после десериализации
    TableDetails.parse(parsedData);

    return parsedData;
  } catch {
    throw new Error("Can't deserialize TableDetails");
  }
}
