import { z } from "zod";

import { BookingData } from "../model";
import { RestaurantInfo } from "./../model";

export const BookingFormLocalStateSchema = z.object({
  bookingData: BookingData.partial(),
  restaurant: RestaurantInfo,
  expiresAt: z.number(),
});
