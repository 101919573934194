import { BookingTime, ShiftData } from "containers/CreateBookingForm/model";
import { z } from "zod";

import { deserializeShiftData } from "../utils";

export const GuestAndTimeFormSchema = z.object({
  persons: z.string().transform(Number).pipe(z.number().int().min(2)),
  shift: z.string().transform((val) => deserializeShiftData(val)),
  visitDuration: z.number().int(),
  bookingTime: BookingTime,
});
export type GuestAndTimeFormSchema = z.infer<typeof GuestAndTimeFormSchema>;
export type GuestAndTimeFormSchemaInput = z.input<
  typeof GuestAndTimeFormSchema
>;
