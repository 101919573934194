import { FormClose, FormHeader } from "components/MultiStepForm";
import { PlaceSelect } from "components/PlaceSelect";
import { NewScheme } from "components/hall-scheme/redux/HallSchemaV2/NewScheme";
import type { TableDetails } from "containers/CreateBookingForm/model";
import {
  placeSelector,
  useApplicationContextActions,
} from "features/AppContext";
import {
  usePrepareShiftsSchedule,
  useSlots,
} from "features/api/hallschema-api";
import { useFetchSeatSlotsQuery } from "features/api/slots";
import type { RestaurantId } from "models/restaurant.model";
import type { ShiftId } from "models/shift.model";
import { useCallback, useState } from "react";
import { type Control, useController, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ETranslations } from "types/translates";
import { Button } from "ui-kit";
import { Danger } from "ui-kit/ICONS/icons";

import type { TableFormSchemaInput } from "../../model/schema";
import { deserializeTableData, serializeTableData } from "../../utils";
import styles from "./TableModal.module.scss";

type Props = {
  reservationInfo?: string;
  control: Control<TableFormSchemaInput>;
  onClose: () => void;
  restaurantId: RestaurantId;
  date: string;
  shiftId: ShiftId;
  time: string;
  visitDuration: number;
  persons: number;
  onTablesChoose: (tables: TableDetails[]) => void;
};

export function TableModal({
  onClose,
  reservationInfo,
  control,
  restaurantId,
  date,
  shiftId,
  time,
  visitDuration,
  persons,
  onTablesChoose,
}: Props) {
  const { data: slots } = useSlots();
  const tables = useWatch({ control, name: "tables" });
  const { data } = useFetchSeatSlotsQuery({
    restaurantId,
    date,
    persons,
    shiftId,
    time,
    visitDuration,
  });
  const { formatMessage } = useIntl();
  const [selectedTables, setSelectedTables] = useState(() =>
    tables.map((tableString) => deserializeTableData(tableString as string)),
  );

  usePrepareShiftsSchedule();

  const placeId = useSelector(placeSelector);
  const [initials] = useState({ placeId });
  const { setPlace } = useApplicationContextActions();

  const handleClose = useCallback(() => {
    // сброс зала на изначальный
    setPlace(initials.placeId);
    // закрытие
    onClose();
  }, [onClose, initials]);

  const selectedTableIds = new Set(
    selectedTables.map((table) => table.tableId),
  );

  return (
    <section className={styles.tableModal}>
      <FormHeader
        title={formatMessage({ id: ETranslations.SELECT_TABLE_MODAL_TITLE })}
      >
        <div className={styles.placeSelector}>
          <PlaceSelect />
        </div>
        <FormClose onClose={handleClose} />
      </FormHeader>
      <div className={styles.content}>
        <NewScheme
          selectedTableIds={selectedTableIds}
          onSelectTable={(table: TableDetails) =>
            setSelectedTables((prev) => prev.concat(table))
          }
          onDeselectTable={(table: TableDetails) => {
            setSelectedTables((prev) =>
              prev.filter(
                (selectedTable) => selectedTable.tableId !== table.tableId,
              ),
            );
          }}
          hallInfo={slots ?? []}
          customPlaceId={placeId}
          timelineToolbar={
            <div className={styles.timelineToolbar}>
              <div className={styles.toolbarInner}>
                <div className={styles.info}>
                  {reservationInfo && (
                    <>
                      <Danger className={styles.icon} />
                      <p>{reservationInfo}</p>
                    </>
                  )}
                </div>
                <Button
                  variant="primary"
                  onClick={() => onTablesChoose(selectedTables)}
                  disabled={!selectedTableIds.size}
                >
                  {formatMessage({
                    id: ETranslations.BASE_CHOOSE,
                  })}
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </section>
  );
}
