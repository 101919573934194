import { type ReactNode, createContext, useContext } from "react";
import { invariant } from "utils";

export type RadioGroupContext<T extends boolean> = {
  groupName: string;
  variant?: "flat" | "bordered" | "compact";
  disabledAll?: boolean;
  multiple?: T;
  defaultValue?: T extends true ? string[] | number[] : string | number;
  activeValue?: T extends true ? string[] | number[] : string | number;
};

const radioGroupContext = createContext<RadioGroupContext<boolean> | null>(
  null,
);

export const useRadioGroupContext = <T extends boolean>() => {
  const value = useContext(radioGroupContext);
  return (value ||
    invariant(
      value,
      "RadioGroupContext context not passed, please wrap your components with <RadioGroupContextProvider />",
    )) as RadioGroupContext<T>;
};

const RadioGroupContextProvider = <T extends boolean>({
  children,
  context,
}: {
  children: ReactNode;
  context: RadioGroupContext<T>;
}) => {
  return (
    <radioGroupContext.Provider value={context}>
      {children}
    </radioGroupContext.Provider>
  );
};

RadioGroupContextProvider.displayName = "RadioGroupContextProvider";

export { RadioGroupContextProvider };
