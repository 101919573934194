import cn from "classnames";
import type { UserUUID } from "models/user.model";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Outlet, useRouteError } from "react-router-dom";
import { useCheckPermission } from "services/permissionChecker";
import { ETranslations } from "types/translates";
import { Collapses, ICONS, IconWithCaption, LinkButton, Spinner } from "ui-kit";

import { userRoleList } from "../useRoleList";
import styles from "./Roles.module.scss";
import { RolesHeader } from "./RolesHeader";

const RoleCard = ({
  userUUID,
  userName,
  restaurantNames,
  phone,
}: {
  userUUID: UserUUID;
  userName: string;
  restaurantNames: string;
  phone?: string;
}) => {
  const [isRestaurantsExpanded, setIsRestaurantsExpanded] = useState(false);
  const { hasPermissionFor } = useCheckPermission();
  return (
    <article
      className={cn(
        styles.roleCard,
        isRestaurantsExpanded && styles.expandedRestaurants,
      )}
    >
      <p className={styles.userName}>{userName}</p>
      <button
        className={styles.restaurantsButton}
        onClick={() => setIsRestaurantsExpanded(true)}
      >
        <IconWithCaption
          caption={restaurantNames}
          title={isRestaurantsExpanded ? undefined : restaurantNames}
          captionClassName={cn(
            styles.restaurants,
            isRestaurantsExpanded && styles.expandedRestaurants,
          )}
        >
          <ICONS.Restaurant />
        </IconWithCaption>
      </button>
      {
        <IconWithCaption caption={phone}>
          <ICONS.USER_PHONE />
        </IconWithCaption>
      }
      {hasPermissionFor("FEATURE_MANAGEMENT_ROLE_ASSIGNING") && (
        <aside>
          <LinkButton
            variant="phantom"
            className={styles.edit}
            to={`${userUUID}/edit`}
          >
            <ICONS.Edit />
          </LinkButton>
          <LinkButton
            variant="phantom"
            className={styles.delete}
            to={`${userUUID}/delete`}
          >
            <ICONS.TrashBin />
          </LinkButton>
        </aside>
      )}
    </article>
  );
};

const NoRoles = ({
  message,
  isLoading,
}: {
  message?: string;
  isLoading?: boolean;
}) => {
  const { formatMessage } = useIntl();
  return isLoading ? (
    <Spinner />
  ) : (
    <p className={styles.noRoles}>
      <strong>
        {message || formatMessage({ id: ETranslations.NOT_FOUND })}
      </strong>
    </p>
  );
};

export const Roles = () => {
  const { options, isLoading, filter } = userRoleList();

  return (
    <>
      <RolesHeader />
      {options?.length ? (
        <Collapses.Root isExpandedAll={Boolean(filter)} options={options}>
          {(user) => (
            <Collapses.Item key={user.userUUID}>
              <RoleCard
                userUUID={user.userUUID}
                userName={user.userName}
                restaurantNames={user.restaurantNames}
                phone={user.phone}
              />
            </Collapses.Item>
          )}
        </Collapses.Root>
      ) : (
        <NoRoles isLoading={isLoading} />
      )}
      <Outlet />
    </>
  );
};

export const RolesErrorBoundary = () => {
  const routeError = useRouteError();

  const errorMessage =
    routeError instanceof Error
      ? routeError.message
      : JSON.stringify(routeError);
  const { formatMessage } = useIntl();
  return (
    <>
      <RolesHeader />
      <NoRoles
        message={formatMessage(
          { id: ETranslations.ERROR_ENTITY },
          {
            entity: formatMessage({
              id: ETranslations.USERS_ALT,
            }).toLowerCase(),
            error:
              errorMessage in ETranslations
                ? formatMessage({ id: errorMessage })
                : errorMessage,
          },
        )}
      />
    </>
  );
};
