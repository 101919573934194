import { getHoursSuffix } from "containers/CreateBookingForm/utils";
import { useCallback } from "react";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

import type { GuestAndTimeFormSchemaInput } from "../model/schema";

export const BookingDurationSelect = ({
  name,
  label,
  control,
  timeInterval,
}: {
  name: "visitDuration";
  label: string;
  control: Control<GuestAndTimeFormSchemaInput>;
  timeInterval: number;
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules: { required: true },
  });
  const { formatMessage } = useIntl();

  const getDurationOptionLabel = useCallback(
    (time: number) => {
      const hours = Math.trunc(time / 60);
      const minutes = time % 60;
      return `${hours} ${formatMessage({
        id: getHoursSuffix(hours),
      }).toLowerCase()} ${minutes}  ${formatMessage({
        id: ETranslations.PLURAL_MINUTES,
      }).toLowerCase()}`;
    },
    [formatMessage],
  );

  const options = Array.from({ length: 48 }, (_, k) => {
    const time = (k + 1) * timeInterval;
    return {
      label: getDurationOptionLabel(time),
      value: time,
    };
  });

  return (
    <SelectBasic
      required
      label={label}
      options={options}
      value={{
        label: getDurationOptionLabel(field.value),
        value: field.value,
      }}
      onChange={(option) =>
        option && "value" in option && field.onChange(option.value)
      }
      captureMenuScroll
      error={fieldState.error?.message}
    />
  );
};
