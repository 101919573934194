import type { CSSObject } from "@emotion/react";
import cn from "classnames";
import { type ReactNode, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import Select, {
  OptionTypeBase,
  Props,
  components as SelectComponents,
  StylesConfig,
} from "react-select";
import { useUnmount } from "react-use";
import { FieldError } from "ui-kit/FieldError";
import { Labeled } from "ui-kit/Labeled";

import { ETranslations } from "../../types/translates";
import styles from "./Select.module.scss";
import type { SelectStyles } from "./helpers";

export type OptionSelectType = {
  label: string;
  value: number | string;
};

const getCommonStyles = (
  styleObject: SelectStyles,
): StylesConfig<OptionSelectType, false> => ({
  control: (base, state) => {
    const {
      menuIsOpen,
      isDisabled,
      selectProps: { menuPlacement },
    } = state;
    const menuIsTop = menuPlacement === "top";

    const borderColor = `var(--${
      menuIsOpen ? "gl_dividerBorder_accent" : "gl_dividerBorder_primary"
    })`;
    const boxShadow = `var(--${menuIsOpen ? "shadow-lightest" : "none"})`;
    const backgroundColor = `var(--${
      isDisabled ? "select_disabled_background" : "select_default_background_1"
    })`;
    const borderBottomColor = menuIsTop
      ? borderColor
      : "var(--gl_dividerBorder_primary)";

    return {
      ...base,
      border: `1px solid ${borderColor}`,
      boxShadow,
      borderRadius: 3,
      borderBottomColor,
      backgroundColor,
      minHeight: 31,
      boxSizing: "border-box",

      ":hover": {
        border: `1px solid ${borderColor}`,
        boxShadow,
        borderBottomColor,
      },
      ...styleObject.control,
    } as CSSObject;
  },
  placeholder: (base) =>
    ({
      ...base,
      font: "var(--font-12-r)",
      fontWeight: 400,
      color: "var(--gl_text_secondary_disabled)",
      ...styleObject.placeholder,
    }) as CSSObject,
  singleValue: (base, { isDisabled }) =>
    ({
      ...base,
      font: "var(--font-12-r)",
      fontWeight: 400,
      color: isDisabled
        ? "var(--gl_text_secondary_disabled)"
        : "var(--gl_text_inverse)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...styleObject.singleValue,
    }) as CSSObject,
  indicatorSeparator: (base) =>
    ({
      ...base,
      display: "none",
      ...styleObject.indicatorSeparator,
    }) as CSSObject,
  menuList: (base) =>
    ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      borderTop: "none",
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "var(--gl_text_secondary_disabled)",
      },
      ...styleObject.menuList,
    }) as CSSObject,
  menuPortal: (base) =>
    ({
      ...base,
      border: "none",
      zIndex: 1000,
      ...styleObject.menuPortal,
    }) as CSSObject,
  menu: (base, state) => {
    const {
      selectProps: { menuPlacement },
    } = state;
    const menuIsTop = menuPlacement === "top";

    const marginTop = menuIsTop ? 0 : "-5px";
    const marginBottom = menuIsTop ? "-5px" : 0;
    const borderBottomColor = menuIsTop
      ? "transparent"
      : "var(--gl_dividerBorder_accent)";
    const borderTopColor = menuIsTop
      ? "var(--gl_dividerBorder_accent)"
      : "transparent";

    return {
      ...base,
      marginTop,
      marginBottom,
      border: "1px solid var(--gl_dividerBorder_accent)",
      borderBottomColor,
      borderTopColor,
      boxShadow: "none",
      backgroundColor: "var(--select_default_background_1)",
      zIndex: 100,
      ...styleObject.menu,
    } as CSSObject;
  },
  indicatorsContainer: (base, props) =>
    ({
      ...base,
      position: "relative",
      height: 28,
      color: "var(--gl_icon_constant_secondary)",
      div: {
        padding: 2,
        ":hover": {
          color: "var(--gl_icon_constant_secondary)",
        },
      },
      "div:last-child": {
        svg: {
          transition: "transform 0.3s",
          transform: props.selectProps.menuIsOpen
            ? "rotate(180deg)"
            : undefined,
        },
      },
      ...styleObject.indicatorsContainer,
    }) as CSSObject,
  valueContainer: (base) =>
    ({
      ...base,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...styleObject.valueContainer,
    }) as CSSObject,
  option: (base, state) =>
    ({
      ...base,
      font: "var(--font-12-r)",
      fontWeight: 400,
      color: "var(--gl_text_inverse)",
      backgroundColor: state.isSelected
        ? "var(--dtpkrListItem_active_background)"
        : "var(--dtpkrListItem_default_background)",
      ":hover": {
        backgroundColor: "var(--dtpkrListItem_hover_background)",
      },
      ...styleObject.option,
    }) as CSSObject,
  input: (base) =>
    ({
      ...base,
      color: "var(--gl_text_inverse)",
      ...styleObject.input,
    }) as CSSObject,
  multiValue: (base) =>
    ({
      ...base,
      ...styleObject.multiValue,
    }) as CSSObject,
  multiValueLabel: (base) =>
    ({
      ...base,
      ...styleObject.multiValueLabel,
    }) as CSSObject,
});

export interface SelectBasicProps<T extends OptionTypeBase>
  extends Props<T, boolean> {
  error?: string | string[];
  isValid?: boolean;
  styleObject?: SelectStyles;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export const SelectBasic = ({
  className,
  styles: customStyles,
  styleObject,
  components,
  isValid = true,
  options,
  error,
  placeholder,
  label,
  prefix,
  suffix,
  ...props
}: SelectBasicProps<OptionSelectType>) => {
  const Container = label ? Labeled : "div";
  const intl = useIntl();
  const intlOptions = options?.map((option) => {
    if (typeof option.label === "string" && option.label in ETranslations) {
      return {
        ...option,
        label: intl.formatMessage({ id: option.label }),
      };
    }
    return option;
  });

  const Control = useCallback(
    ({ children, ...controlProps }) => {
      const { menuIsOpen } = controlProps;
      return (
        <SelectComponents.Control
          {...controlProps}
          className={cn(
            controlProps.className,
            !isValid && !menuIsOpen && styles.invalid,
          )}
        >
          {children}
        </SelectComponents.Control>
      );
    },
    [isValid],
  );

  const [portal] = useState(() => {
    const el = document.createElement("div");
    if (props.name) {
      el.dataset.modal = props.name;
    }
    document.body.append(el);
    return el;
  });

  useUnmount(() => {
    try {
      document.body.removeChild(portal);
    } catch {
      //
    }
  });

  return (
    <Container
      label={label}
      className={cn(styles.container, className)}
      required={props.required}
    >
      {prefix}
      <Select
        components={{
          Control,
          ...components,
        }}
        isSearchable={false}
        menuPortalTarget={portal}
        noOptionsMessage={() =>
          intl.formatMessage({ id: ETranslations.BASE_NO_VALUES })
        }
        options={intlOptions}
        // @ts-ignore
        styles={{
          ...getCommonStyles(styleObject || {}),
          ...customStyles,
        }}
        menuShouldBlockScroll
        placeholder={
          placeholder ??
          intl.formatMessage(
            { id: ETranslations.BASE_SELECT_ENTITY },
            {
              entity: label?.toLowerCase() || "",
            },
          )
        }
        {...props}
      />
      {suffix}
      {error && (
        <FieldError error={error} fieldName={label || props["aria-label"]} />
      )}
    </Container>
  );
};
