import { StepContainer } from "components/MultiStepForm";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import { useCreateBookingFormContext } from "containers/CreateBookingForm/Provider";
import type { StepNumber } from "containers/CreateBookingForm/model";
import dayjs from "dayjs";
import { type FormEventHandler, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./BookingDateStep.module.scss";
import { SmallCalendar } from "./SmallCalendar";

const STEP_NUMBER: StepNumber = 1;

export const BookingDateStep = () => {
  const disabledDaysSet = new Set([
    "2024-10-11",
    "2024-10-14",
    "2024-10-15",
    "2024-10-16",
    "2024-10-22",
    "2024-10-25",
    "2024-11-05",
    "2024-11-11",
    "2024-11-18",
    "2024-12-01",
    "2024-12-10",
    "2024-12-25",
  ]);

  const {
    bookingData,
    updateBookingData,
    updateStepValidationState,
    stepValidationState,
    findNextInvalidStep,
  } = useCreateBookingFormContext();

  const now = dayjs.tz().format("YYYY-MM-DD");

  const navigate = useNavigate();

  // Если дефолтно выбранная дата существует и входит в список запрещенных дат, то поменять статус валидации на false, на true, если нет
  useEffect(() => {
    const isBookingDateValid = Boolean(
      bookingData.bookingDate &&
        !disabledDaysSet.has(bookingData.bookingDate) &&
        now <= bookingData.bookingDate,
    );
    // Вызываем update, только если валидация не сходится
    updateStepValidationState({
      step1: isBookingDateValid,
    });
  }, []);

  const handleBookingDateChange: FormEventHandler<HTMLFormElement> = (e) => {
    const bookingDate = new FormData(e.currentTarget).get("bookingDate");
    // Если в форме есть выбранная дата
    if (bookingDate && !disabledDaysSet.has(String(bookingDate))) {
      // обновлем значения даты в контексте
      updateBookingData({ bookingDate: String(bookingDate) });

      //обеовляем валидацию
      const newValidationState = {
        // Текущий шаг валиден, если даты нет в списке запрещенных
        step1: !disabledDaysSet.has(String(bookingDate)),

        // Второй и четвертый шаг меняют валидацию на undefined, если они был true, так как смена даты требует повторной их валидации
        step2: stepValidationState.step2 && undefined,
        step4: stepValidationState.step4 && undefined,
      };
      updateStepValidationState(newValidationState);
      // Если есть еще не валидные шаги, то редирект пользователя на него
      const nextInvalidStepNumber = findNextInvalidStep(
        STEP_NUMBER,
        newValidationState,
      );
      nextInvalidStepNumber && navigate(`../step-${nextInvalidStepNumber}`);
    }
  };

  return (
    <>
      <StepContainer>
        <form className={styles.form} onChange={handleBookingDateChange}>
          <SmallCalendar
            name="bookingDate"
            defaultIsoDate={
              bookingData.bookingDate || dayjs.tz().format("YYYY-MM-DD")
            }
            disabledDaysSet={disabledDaysSet}
          />
        </form>
      </StepContainer>
      {!disabledDaysSet.has(bookingData.bookingDate) && (
        <CreateBookingFormFooter currentStep={STEP_NUMBER} />
      )}
    </>
  );
};
