import cn from "classnames";
import { ReactNode, forwardRef } from "react";
import { FieldError } from "ui-kit/FieldError";

import styles from "./RadioGroup.module.scss";
import {
  RadioGroupContextProvider,
  useRadioGroupContext,
} from "./RadioGroupContext";

const RadioGroupRoot = <T extends boolean>({
  variant,
  groupName,
  defaultValue,
  activeValue,
  label,
  onChange,
  children,
  required,
  disabled,
  className,
  labelClassName,
  multiple,
  error,
}: {
  variant?: "flat" | "bordered" | "compact";
  groupName: string;
  label?: string;
  onChange?: (value: string) => void;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
  error?: string | string[];
  multiple?: T;
  defaultValue?: T extends true ? string[] | number[] : string | number;
  activeValue?: T extends true ? string[] | number[] : string | number;
}) => {
  const handleRadioChange =
    onChange &&
    ((e: React.FormEvent<HTMLFieldSetElement>) => {
      if (e.target instanceof HTMLInputElement) {
        onChange(e.target.value);
      }
    });

  return (
    <fieldset
      className={cn(styles.radioGroup, className)}
      onChange={handleRadioChange}
    >
      {label && (
        <legend
          className={cn(
            styles.label,
            required && styles.required,
            labelClassName,
          )}
        >
          {label}
        </legend>
      )}
      <RadioGroupContextProvider<T>
        context={{
          variant,
          groupName,
          defaultValue,
          activeValue,
          multiple,
          disabledAll: disabled,
        }}
      >
        {children}
      </RadioGroupContextProvider>
      {error && <FieldError error={error} fieldName={label} />}
    </fieldset>
  );
};

const RadioGroupContent = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    className?: string;
  }
>(({ children, className }, ref) => {
  const { variant } = useRadioGroupContext();

  return (
    <div
      ref={ref}
      className={cn(styles.buttons, styles[variant || "flat"], className)}
    >
      {children}
    </div>
  );
});

type RadioGroupButtonProps<T extends boolean> = {
  id?: string | number;
  value: string | number;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  anchor?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "value">;

const RadioGroupButton = forwardRef(
  <T extends boolean>(
    {
      id,
      value,
      disabled,
      children,
      className,
      anchor,
      ...rest
    }: RadioGroupButtonProps<T>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const {
      variant,
      groupName,
      defaultValue,
      activeValue,
      disabledAll,
      multiple,
    } = useRadioGroupContext<T>();

    const inputId = `${groupName}-${id ?? value}`;

    const defaultChecked = multiple
      ? (defaultValue as (string | number)[])?.includes(value)
      : value === defaultValue;
    const checked = multiple
      ? (activeValue as (string | number)[])?.includes(value)
      : value === activeValue;

    return (
      <>
        <input
          id={inputId}
          // Будет перезаписан, если отправлен новый name в ...rest
          name={groupName}
          value={value}
          // Будет перезаписан, если отправлен новый defaultChecked в ...rest
          defaultChecked={defaultChecked || undefined}
          // Будет перезаписан, если отправлен новый checked в ...rest
          checked={checked || undefined}
          disabled={disabled || disabledAll}
          type={multiple ? "checkbox" : "radio"}
          hidden
          ref={ref}
          {...rest}
        />
        <label
          id={anchor}
          htmlFor={inputId}
          className={cn(
            styles.radioButton,
            styles[variant || "flat"],
            className,
          )}
        >
          {children ?? value}
        </label>
      </>
    );
  },
);

RadioGroupRoot.displayName = "RadioGroup.Root";
RadioGroupContent.displayName = "RadioGroup.Content";
RadioGroupButton.displayName = "RadioGroup.Button";

export const RadioGroup = {
  Root: RadioGroupRoot,
  Content: RadioGroupContent,
  Button: RadioGroupButton,
};
