import { zodResolver } from "@hookform/resolvers/zod";
import { StepContainer } from "components/MultiStepForm";
import { CreateBookingFormFooter } from "containers/CreateBookingForm/Layout";
import {
  useCreateBookingFormContext,
  useShiftDetails,
} from "containers/CreateBookingForm/Provider";
import {
  DEFAULT_VISIT_DURATION,
  MAX_PERSON_BUTTON_COUNT,
  MIN_PERSON_BUTTON_COUNT,
} from "containers/CreateBookingForm/constants";
import type {
  BookingData,
  ShiftDetails,
  StepNumber,
} from "containers/CreateBookingForm/model";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { ICONS, IconWithCaption, RadioGroup, Spinner } from "ui-kit";
import { commonFormErrorMap } from "utils";

import {
  GuestAndTimeFormSchema,
  type GuestAndTimeFormSchemaInput,
} from "../model/schema";
import { serializeShiftData } from "../utils";
import { BookingDurationSelect } from "./BookingDurationSelect";
import styles from "./GuestAndTimeStep.module.scss";
import { GuestCountSelect } from "./GuestCountCustomSelect";
import { GuestRadioGroup } from "./GuestRadioGroup";
import { ShiftRadioGroup } from "./ShiftRadioGroup";
import { TimeRadioGroup } from "./TimeRadioGroup";

const STEP_NUMBER: StepNumber = 2;

const GuestAndTimeStep = ({
  shiftDetails,
  defaultValues,
}: {
  shiftDetails: ShiftDetails;
  defaultValues: {
    persons: string;
    visitDuration: number;
    shift: string;
  };
}) => {
  const { formatMessage } = useIntl();
  const {
    bookingData,
    restaurant,
    updateBookingData,
    updateStepValidationState,
  } = useCreateBookingFormContext();
  const { register, handleSubmit, formState, control, setError, clearErrors } =
    useForm<GuestAndTimeFormSchemaInput, unknown, GuestAndTimeFormSchema>({
      resolver: zodResolver(GuestAndTimeFormSchema, {
        errorMap: commonFormErrorMap,
      }),
      defaultValues,
      mode: "all",
    });

  const handleGuestAndTimeStepSubmit = () => {
    let success = false;
    let data: BookingData;
    return handleSubmit(
      ({ bookingTime, persons, visitDuration, shift }) => {
        data = updateBookingData({
          bookingTime,
          persons,
          visitDuration,
          shift,
        });
        updateStepValidationState({ step2: true });
        success = true;
      },
      () => {
        updateStepValidationState({ step2: false });
        success = false;
      },
    )().then(
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
      () =>
        ({ success, data }) as
          | {
              success: true;
              data: BookingData;
            }
          | {
              success: false;
              data: undefined;
            },
    );
  };

  return (
    <>
      <StepContainer>
        <form className={styles.guestAndTimeForm}>
          <GuestRadioGroup
            name="persons"
            min={MIN_PERSON_BUTTON_COUNT}
            max={MAX_PERSON_BUTTON_COUNT}
            defaultValue={defaultValues.persons}
            CustomGuestCountComponent={
              <GuestCountSelect
                name="persons"
                min={MAX_PERSON_BUTTON_COUNT}
                control={control}
              />
            }
            error={formState.errors.persons?.message}
          >
            {(value) => (
              <RadioGroup.Button
                {...register("persons", { required: true })}
                key={value}
                value={value}
              />
            )}
          </GuestRadioGroup>

          <ShiftRadioGroup
            name="shift"
            shiftDetails={shiftDetails}
            error={formState.errors.shift?.message}
            onChange={() => formState.errors.shift && clearErrors("shift")}
          >
            {(buttonParam) => (
              <RadioGroup.Button
                {...register("shift", { required: true })}
                key={buttonParam.value}
                value={buttonParam.value}
                disabled={buttonParam.disabled}
              >
                {buttonParam.text}
              </RadioGroup.Button>
            )}
          </ShiftRadioGroup>

          <BookingDurationSelect
            name="visitDuration"
            label={formatMessage({ id: ETranslations.RESERVATION_DURATION })}
            control={control}
            timeInterval={15}
          />

          {!formState.errors.shift && (
            <TimeRadioGroup
              name="bookingTime"
              control={control}
              restaurantId={restaurant.id}
              bookingDate={bookingData.bookingDate}
              defaultBookingTime={bookingData.bookingTime}
              error={formState.errors.bookingTime?.message}
            >
              {(timeSlot) => (
                <RadioGroup.Button
                  className={styles.timeSlot}
                  {...register("bookingTime", { required: true })}
                  value={timeSlot.value}
                >
                  {timeSlot.label}
                  <IconWithCaption
                    caption={`${timeSlot.slotCoveredIn}-${timeSlot.slotMaxCoveredIn}`}
                    className={styles.coveredIn}
                  >
                    <ICONS.People width={18} height={18} />
                  </IconWithCaption>
                </RadioGroup.Button>
              )}
            </TimeRadioGroup>
          )}
        </form>
      </StepContainer>
      {formState.isValid && (
        <CreateBookingFormFooter
          currentStep={STEP_NUMBER}
          onBeforeNavigate={handleGuestAndTimeStepSubmit}
        />
      )}
    </>
  );
};

export const GuestAndTimeStepWrapper = () => {
  const { bookingData, restaurant } = useCreateBookingFormContext();
  const { shiftDetails, isLoading } = useShiftDetails({
    restaurantId: restaurant.id,
    date: bookingData.bookingDate,
    time: bookingData.bookingTime,
  });
  if (isLoading || !shiftDetails)
    return (
      <StepContainer>
        <Spinner />
      </StepContainer>
    );

  const isShiftValid = shiftDetails.details.some(
    (shift) => JSON.stringify(shift) === JSON.stringify(bookingData.shift),
  );

  const defaultShift = isShiftValid
    ? bookingData.shift
    : shiftDetails.defaultValue;
  const defaultPersons = bookingData.persons || MIN_PERSON_BUTTON_COUNT;
  const defaultVisitDuration =
    bookingData.visitDuration || DEFAULT_VISIT_DURATION;

  return (
    <GuestAndTimeStep
      shiftDetails={shiftDetails}
      defaultValues={{
        persons: String(defaultPersons),
        visitDuration: defaultVisitDuration,
        shift: serializeShiftData(defaultShift),
      }}
    />
  );
};
