import { bookingFormLocalStore, CACHED_TIME } from "containers/CreateBookingForm/localStore/bookingFormLocalStore";
import type { BookingTime, LoaderData } from "containers/CreateBookingForm/model";
import { shiftsApi } from "features/api/shifts";
import { slotsApi } from "features/api/slots";
import { type LoaderFunction, redirect } from "react-router-dom";
import { getPermissionChecker } from "services/permissionChecker";
import { loadStore } from "storage";



import { getApplicationContext } from "./getApplicationContext";
import { formatToDateTime, getShiftDetails } from "./getShiftDetails";


export const loader = (async () => {
  const store = await loadStore();
  const { hasPermissionFor } = await getPermissionChecker(store);

  if (!hasPermissionFor("ACCESS_BOOKING_CREATE", true)) {
    return redirect("/hall-scheme");
  }

  const { currentDate, defaultRestaurant, restaurantsData, currentUser } =
    await getApplicationContext(store);

  const bookingDate = currentDate.format("YYYY-MM-DD");

  // Получаем детали по сменам: id всех смен, начала и конец каждой смены (части смены),
  // а также дефолтную смену/часть смены, из расчета currentDate

  const shiftSlots = await store
    .dispatch(
      slotsApi.endpoints.fetchShiftSlots.initiate({
        restaurantId: defaultRestaurant.id,
        date: bookingDate,
      }),
    )
    .unwrap();

  const defaultShiftDetails = getShiftDetails(
    shiftSlots,
    formatToDateTime(currentDate),
  );

  const { cachedBookingData, cachedRestaurant } = await bookingFormLocalStore
    .getAllItems()
    .then((items) => {
      const isExpired = !items.expiresAt || items.expiresAt < Date.now();
      const isSameUser = items.bookingData?.userId === currentUser.user_id;
      (isExpired || !isSameUser) && bookingFormLocalStore.clear();

      return !isExpired && isSameUser && items.bookingData
        ? {
            cachedRestaurant: items.restaurant,
            cachedBookingData: items.bookingData,
          }
        : { cachedRestaurant: undefined, cachedBookingData: undefined };
    });

  bookingFormLocalStore.setItem("expiresAt", Date.now() + CACHED_TIME);
  !cachedRestaurant && bookingFormLocalStore.setItem('restaurant', defaultRestaurant)

  const loaderData = {
    defaultBookingDate: bookingDate,
    defaultBookingTime: currentDate.format("HH:mm:ss") as BookingTime,
    defaultRestaurant,
    cachedRestaurant,
    restaurantsData,
    defaultShiftDetails,
    userId: currentUser.user_id,
    cachedBookingData,
  } satisfies LoaderData;

  return loaderData;
}) satisfies LoaderFunction;