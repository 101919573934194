import cn from "classnames";
import type { ReactNode } from "react";

import styles from "./StepContainer.module.scss";

const StepContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <main className={cn(styles.content, className)}>{children}</main>;
};

StepContainer.displayName = "StepContainer";
export { StepContainer };
