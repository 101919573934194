import { predata } from "common/helpers";
import { useApplicationContextActions } from "features/AppContext";
import { IResponse } from "models/common";
import type { PlaceId, RestaurantId } from "models/restaurant.model";
import { Place, Restaurant } from "models/restaurant.model";
import { Notification } from "services/notification";
import type { ErrorResponse } from "types/commons";
import { UpdatePlaceDTO } from "types/place";
import { ETranslations } from "types/translates";

import { api } from "./api";

export const restaurantsApi = api
  .enhanceEndpoints({
    addTagTypes: ["Restaurants"],
  })
  .injectEndpoints({
    endpoints: ($) => ({
      fetchAllRestaurants: $.query<
        {
          restaurants: Restaurant[];
          restaurantById: Record<RestaurantId, Restaurant>;
        },
        void
      >({
        query: () => ({
          url: "v2/restaurants",
        }),
        providesTags: ["Restaurants"],
        transformResponse: (response: IResponse<Restaurant[]>) =>
          response.data.reduce(
            (acc, rest) => {
              const restaurant = {
                ...rest,
                places: rest.places.sort((a, b) => a.weight - b.weight),
              };
              acc.restaurants.push(restaurant);
              acc.restaurantById[restaurant.restaurant_id] = restaurant;
              return acc;
            },
            {
              restaurants: new Array<Restaurant>(),
              restaurantById: {} as Record<RestaurantId, Restaurant>,
            },
          ),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            data.restaurants.forEach((rest) => {
              predata.restsMapper[rest.restaurant_id] = {
                label: rest.restaurant_name,
                value: rest.restaurant_id,
                ...rest,
              };
            });
          } catch (e) {
            (e as ErrorResponse)?.error?.data?.status === 500 &&
              global.channels.auth.cb?.(false); // временная мера, убрать как только бэк станет возвращать 401 при инвалидном токене
          }
        },
      }),
      updateHall: $.mutation<Place, Partial<UpdatePlaceDTO>>({
        query: (body) => ({
          url: "v2/places",
          method: "POST",
          body: {
            ...body,
            schedule: body.schedule ?? [
              {
                duration: 10,
                booking_interval: 10,
                time_start: "12:00:00",
                week_day: 1,
              },
            ],
          },
        }),
        invalidatesTags: (_res, err) => (!err ? ["Restaurants"] : []),
        transformResponse: (response: IResponse<Place>) => response.data,
      }),
      deleteHall: $.mutation({
        query: (id: number | string) => ({
          url: `v2/places/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Restaurants"],
      }),
    }),
  });

export const {
  useFetchAllRestaurantsQuery,
  useUpdateHallMutation,
  useDeleteHallMutation,
} = restaurantsApi;

export const useFetchAllRestaurants = () => {
  const { data, ...rest } = useFetchAllRestaurantsQuery();
  data?.restaurants.length === 0 &&
    Notification.error(ETranslations.UNKNOWN_RESTAURANT);
  const { setUpRestaurants, setSelectedPlaces, setPlace, setRestaurant } =
    useApplicationContextActions();
  const restId = localStorage.getItem("restaurantId");
  const restaurant =
    data &&
    (data.restaurants.find((r) => r.restaurant_id === Number(restId)) ||
      data.restaurants[0]);
  const selectedPlaces =
    restaurant?.places && restaurant.places.map((p) => p.id);
  data && setUpRestaurants(data.restaurants);
  setSelectedPlaces(selectedPlaces || []);
  selectedPlaces?.length && setPlace(selectedPlaces[0]);
  restaurant && setRestaurant(restaurant);
  return { data, restaurant, ...rest };
};

export const useRestaurantPlaces = (restaurantId: RestaurantId) => {
  const { data } = useFetchAllRestaurantsQuery();
  const places = data?.restaurantById[restaurantId].places || [];
  const placesById = places.reduce<Record<PlaceId, Place>>((acc, place) => {
    acc[place.id as PlaceId] = place;
    return acc;
  }, {});
  return { places, placesById };
};
