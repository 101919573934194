import { Modal } from "components/modal";
import { useResetPasswordMutation } from "features/api/user-api";
import { UserUUID } from "models/user.model";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Notification } from "services/notification";
import { ETranslations } from "types/translates";
import { Button, ICONS } from "ui-kit";

import styles from "../UserForm.module.scss";

export const ResetPasswordModal = ({
  userUUID,
  onResetPassword,
}: {
  userUUID: UserUUID;
  onResetPassword: () => void;
}) => {
  const { formatMessage } = useIntl();
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handlePasswordReset = () =>
    resetPassword(userUUID).then((resp) => {
      const isResetPasswordSuccess =
        resp && "data" in resp && resp.data === "OK";
      if (isResetPasswordSuccess) {
        Notification.success(ETranslations.RESET_PASSWORD_SUCCESS);
        onResetPassword?.();
      } else {
        Notification.error(ETranslations.RESET_PASSWORD_ERROR);
      }
    });

  return (
    <>
      <Button
        variant="phantom"
        className={styles.textButton}
        onClick={() => setIsResettingPassword(true)}
      >
        {formatMessage(
          { id: ETranslations.BASE_RESET },
          {
            entity: formatMessage({
              id: ETranslations.AUTH_PASSWORD,
            }).toLowerCase(),
          },
        )}
      </Button>
      <Modal
        className={styles.conformationModal}
        isOpen={isResettingPassword}
        onClose={() => setIsResettingPassword(false)}
      >
        <Modal.Content className={styles.body}>
          <ICONS.Attention className={styles.icon} />
          <h2>
            {formatMessage(
              { id: ETranslations.BASE_RESET },
              {
                entity: formatMessage({
                  id: ETranslations.AUTH_PASSWORD,
                }).toLowerCase(),
              },
            )}
          </h2>
          <p className={styles.conformationMessage}>
            {formatMessage({ id: ETranslations.RESET_PASSWORD_MESSAGE })}
          </p>
          <div className={styles.actions}>
            <Button
              type="button"
              variant="primary"
              disabled={isLoading}
              onClick={handlePasswordReset}
            >
              {formatMessage({ id: ETranslations.BASE_CONFIRM })}
            </Button>
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsResettingPassword(false)}
            >
              {formatMessage({ id: ETranslations.BASE_CANCEL })}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
