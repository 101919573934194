import cn from "classnames";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";



import styles from "./FieldError.module.scss";


export const FieldError = ({
  error,
  fieldName,
  value,
  max,
  className,
}: {
  error: string | string[];
  fieldName?: string;
  value?: string | number;
  max?: string | number;
  className?: string;
}) => {
  const { formatMessage } = useIntl();
  return Array.isArray(error) ? (
    <ul className={cn(styles.errorList, className)}>
      {Array.from(new Set(error)).map((err) => (
        <li key={String(err)}>
          <strong className={styles.error}>
            {err in ETranslations
              ? formatMessage(
                  { id: ETranslations[err as ETranslations] },
                  {
                    field: fieldName || "",
                    value: value || "",
                    max: max || "",
                  },
                )
              : err}
          </strong>
        </li>
      ))}
    </ul>
  ) : (
    <strong className={cn(styles.error, className)}>
      {error in ETranslations
        ? formatMessage(
            { id: ETranslations[error as ETranslations] },
            { field: fieldName || "", value: value || "" },
          )
        : error}
    </strong>
  );
};