import type { MouseEventHandler } from "react";
import type { To } from "react-router-dom";
import {
  Button,
  type ButtonLinkProps,
  type ButtonProps,
  ICONS,
  LinkButton,
} from "ui-kit";

import styles from "./FormClose.module.scss";

type FormCloseProps =
  | ({
      to?: undefined;
      onClose?: () => void;
    } & Partial<ButtonProps>)
  | ({
      to: To;
      onClose?: () => void;
    } & Partial<ButtonLinkProps>);

const FormClose = ({ to, onClose, onClick, ...props }: FormCloseProps) => {
  return to ? (
    <LinkButton
      className={styles.closeButton}
      {...(props as Partial<ButtonLinkProps>)}
      variant="phantom"
      to={to}
      onClick={(e) => {
        onClose?.();
        onClick?.(e);
      }}
    >
      <ICONS.Cross />
    </LinkButton>
  ) : (
    <Button
      className={styles.closeButton}
      {...(props as Partial<ButtonProps>)}
      variant="phantom"
      onClick={(e) => {
        onClose?.();
        (onClick as MouseEventHandler<HTMLButtonElement> | undefined)?.(e);
      }}
    >
      <ICONS.Cross />
    </Button>
  );
};

FormClose.displayName = "FormClose";
export { FormClose };
