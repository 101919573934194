import type { TableId } from "models/booking.model";
import type { PlaceId } from "models/restaurant.model";
import { createContext } from "use-context-selector";

export type Table = {
  placeId: PlaceId;
  tableId: TableId;
  tableName: string;
};

export type NewSchemeContext = {
  placeId: PlaceId;
  selectedTableIds?: Set<TableId>;
  onSelectTable?: (table: Table) => void;
  onDeselectTable?: (table: Table) => void;
};

export const NewSchemeContext = createContext<NewSchemeContext>(
  {} as NewSchemeContext,
);
