import localforage from "localforage";

import { APP_NAME } from "../constants";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: APP_NAME,
  storeName: `${APP_NAME}Data`,
  description: `Store ${APP_NAME} data`,
  version: 1.0,
});

export default localforage;
