import { useRestaurantPlaces } from "features/api/restaurants";
import { useFetchShiftQuery } from "features/api/shifts";
import type { RestaurantId } from "models/restaurant.model";
import type { Option } from "models/common";
import type { ShiftId } from "models/shift.model";
import type { ReactNode } from "react";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { SelectBasic } from "ui-kit";

import type { TableFormSchemaInput } from "../model/schema";
import styles from "./HallSelect.module.scss";

export const HallSelect = ({
  name,
  restaurantId,
  shiftId,
  control,
  children,
}: {
  name: "placeId";
  restaurantId: RestaurantId;
  shiftId: ShiftId;
  control: Control<TableFormSchemaInput>;
  children?: ReactNode;
}) => {
  const { places } = useRestaurantPlaces(restaurantId);
  // FIXME: убрать запрос шифта, когда бэк добавит информацию places в запрос о всех доступных шифтах
  const shiftInfo = useFetchShiftQuery(shiftId);
  const activePlaces = new Set(shiftInfo.data?.places_id);
  const { formatMessage } = useIntl();

  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const options = places.reduce<Option<number | "all", string | JSX.Element>[]>(
    (acc, place) => {
      const isPlaceActive = activePlaces.has(place.id);
      const option = isPlaceActive
        ? {
            value: place.id,
            label: place.name,
          }
        : {
            value: place.id,
            label: (
              <span
                className={styles.inactiveOption}
              >{`${place.name} (${formatMessage({ id: ETranslations.SHIFT_STATUS_INACTIVE })} ${shiftInfo.data?.name || ""})`}</span>
            ),
          };
      acc.push(option);
      return acc;
    },
    Array({
      value: "all",
      label: formatMessage({ id: ETranslations.ALL_HALLS }),
    }),
  );
  return (
    <SelectBasic
      className={styles.hallSelect}
      //@ts-ignore
      options={options}
      //@ts-ignore
      value={{
        label:
          options.find((option) => String(option.value) === field.value)
            ?.label || "",
        value: field.value,
      }}
      onChange={(option) =>
        option && "value" in option && field.onChange(String(option.value))
      }
      onBlur={field.onBlur}
      captureMenuScroll
      suffix={children}
    />
  );
};
