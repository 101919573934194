import {
  RestaurantInfo,
  RestaurantsData,
} from "containers/CreateBookingForm/model";
import dayjs from "dayjs";
import { restaurantsApi } from "features/api/restaurants";
import type { Place, PlaceId } from "models/restaurant.model";
import type { AppStore } from "storage";
import { ETranslations } from "types/translates";
import { invariant } from "utils";

const getPlacesByIdRecord = (places: Place[]) =>
  places.reduce<RestaurantInfo["placeById"]>((acc, place) => {
    acc[place.id as PlaceId] = place;
    return acc;
  }, {});

export const getApplicationContext = async (store: AppStore) => {
  const applicationContext = store.getState().applicationContext;

  // null, если в сторе еще нет значения
  const currentRestaurant = applicationContext.restaurant;

  const allRestaurants = await store
    .dispatch(restaurantsApi.endpoints.fetchAllRestaurants.initiate())
    .unwrap();

  // restaurant будет равен ресторану который сейчас выбран в приложении или же первый ресторан из доступных пользователю
  const defaultRestaurant = currentRestaurant || allRestaurants.restaurants[0];

  // Если id ресторана все равно undefined, то выбросить исключение
  invariant(
    defaultRestaurant?.restaurant_id,
    ETranslations.UNKNOWN_RESTAURANT,
    true,
  );

  const restaurantsData = allRestaurants.restaurants.reduce(
    (acc, r) => {
      const restaurantInfo = {
        id: r.restaurant_id,
        name: r.restaurant_name,
        places: r.places,
        placeById: getPlacesByIdRecord(r.places),
      };
      acc.restaurants.push(restaurantInfo);
      acc.restaurantById[r.restaurant_id] = restaurantInfo;
      return acc;
    },
    {
      restaurants: new Array<RestaurantInfo>() as [
        RestaurantInfo,
        ...RestaurantInfo[],
      ],
      restaurantById: {},
    } as RestaurantsData,
  );

  const currentDate = dayjs
    .tz(applicationContext.date)
    .tz(defaultRestaurant.timezone);

  const currentUser = applicationContext.currentUser;

  return {
    defaultRestaurant:
      restaurantsData.restaurantById[defaultRestaurant.restaurant_id]!,
    restaurantsData,
    currentDate,
    currentUser,
  };
};
