import { type ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { ETranslations } from "types/translates";
import { RadioGroup } from "ui-kit";

import styles from "./GuestRadioGroup.module.scss";

export const GuestRadioGroup = ({
  name,
  min,
  max,
  children,
  defaultValue,
  CustomGuestCountComponent,
  error,
}: {
  name: string;
  min: number;
  max: number;
  children: (value: number) => ReactNode;
  defaultValue: string;
  CustomGuestCountComponent: JSX.Element | null;
  error?: string | string[];
}) => {
  const { formatMessage } = useIntl();
  const [isCustomGuestCount, setIsCustomGuestCount] = useState(
    Number(defaultValue) >= max,
  );
  return (
    <RadioGroup.Root
      className={styles.guestRadioGroup}
      groupName={name}
      label={formatMessage({ id: ETranslations.NUMBER_OF_GUESTS_TITLE })}
      required
      variant="flat"
      onChange={(value) => setIsCustomGuestCount(Number(value) >= max)}
      error={error}
    >
      <RadioGroup.Content>
        {Array.from({ length: max - min }, (_, k) => children(k + min))}
        <RadioGroup.Button value={max} defaultChecked={Number(defaultValue) >= max}>
          {formatMessage({ id: ETranslations.CUSTOM })}
        </RadioGroup.Button>
      </RadioGroup.Content>
      {isCustomGuestCount && CustomGuestCountComponent}
    </RadioGroup.Root>
  );
};
