import { Client, ClientOrContact, Contact } from "models/client.model";
import { Status, StatusId } from "types/status";
import { z } from "zod";

import { BookingId, SeatType, SlotDeposit, SlotPlace } from "./booking.model";
import { RestaurantId } from "./restaurant.model";
import { Source } from "./source.model";
import { Tag } from "./tags.model";

const UserId = z.number().brand("UserId");

const HistoryId = z.number().brand("HistoryId");
export type HistoryId = z.infer<typeof HistoryId>;

export const BookingHistoryDTO = z.object({
  booking: z.object({
    id: BookingId,
    restaurant_id: RestaurantId,
    date: z.string().date(),
    time: z.string().refine((val) => /^\d\d:\d\d:\d\d$/.test(val)),
    visit_duration: z.number(),
    slot_places: SlotPlace.array(),
    notes: z.record(z.string()),
    visitors: z.number(),
    locked: z.boolean(),
    overbooking: z.boolean(),
    use_deposit: SlotDeposit.shape.use_deposit,
    deposit_status: SlotDeposit.shape.deposit_status,
    deposit_amount: SlotDeposit.shape.deposit_amount,
    tags: Tag.array(),
    status_id: StatusId,
    extra_status_id: StatusId.nullable(),
    source: Source.nullable(),
    contact: z.discriminatedUnion("contact_type", [Client, Contact]),
    seat_type: SeatType,
    comment: z.string(),
    user: z.object({
      serial: z.number().nullable(),
      login: z.string(),
      name: z.string(),
      active: z.boolean(),
      user_id: UserId,
      old_id: z.number().nullable(),
    }),
  }),
  events: z
    .object({
      history_id: HistoryId,
      time: z.string().date(),
      user: z.string(),
      event: z.enum([
        "CREATE",
        "MODIFY",
        "OVERBOOKING",
        "CHANGE_STATUS",
        "CHANGE_EXTRA_STATUS",
        "MOVING_PLACE",
        "RESTORE",
      ]),
    })
    .array(),
});
export type BookingHistoryDTO = z.infer<typeof BookingHistoryDTO>;

export const ParameterName = z.enum([
  "client",
  "comment",
  "date",
  "depositAmount",
  "depositStatus",
  "overbooking",
  "persons",
  "status",
  "tables",
  "tags",
  "visitTime",
  "source",
  "phone",
  "clientName",
]);

const CommonBookingHistoryParam = z.object({
  old: z.never(),
  user_name: z.string(),
  new: z.never(),
});

const ClientChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("client"),
  old: ClientOrContact,
  new: ClientOrContact,
});

const CommentChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("comment"),
  old: z.string(),
  new: z.string(),
});

const DateChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("date"),
  old: z.string().datetime({ offset: true }),
  new: z.string().datetime({ offset: true }),
});

const DepositAmountChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("depositAmount"),
  old: z.number(),
  new: z.number(),
});

const DepositStatusChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("depositStatus"),
  old: z.string(),
  new: z.string(),
});

const UseDepositChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("useDeposit"),
  old: z.boolean(),
  new: z.boolean(),
});

const OverbookingChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("overbooking"),
  old: z.boolean(),
  new: z.boolean(),
});

const PersonsChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("persons"),
  old: z.number(),
  new: z.number(),
});

const StatusChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("status"),
  old: Status,
  new: Status,
});

const TablesChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("tables"),
  old: z.string().array(),
  new: z.string().array(),
});

const TagsChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("tags"),
  old: Tag.array(),
  new: Tag.array(),
});

const VisitTimeChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("visitTime"),
  old: z.number(),
  new: z.number(),
});

const SourceChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("source"),
  old: Source,
  new: Source,
});

const PhoneChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("phone"),
  old: z.string(),
  new: z.string(),
});

const ClientNameChronologicalChanges = CommonBookingHistoryParam.extend({
  parameter_name: z.literal("clientName"),
  old: z.string(),
  new: z.string(),
});

export const BookingHistoryParam = z.discriminatedUnion("parameter_name", [
  ClientChronologicalChanges,
  CommentChronologicalChanges,
  DateChronologicalChanges,
  DepositAmountChronologicalChanges,
  DepositStatusChronologicalChanges,
  UseDepositChronologicalChanges,
  OverbookingChronologicalChanges,
  PersonsChronologicalChanges,
  StatusChronologicalChanges,
  TablesChronologicalChanges,
  TagsChronologicalChanges,
  VisitTimeChronologicalChanges,
  SourceChronologicalChanges,
  PhoneChronologicalChanges,
  ClientNameChronologicalChanges,
]);
export type BookingHistoryParam = z.infer<typeof BookingHistoryParam>;

export const BookingHistoryDetailsChronologicalChangesDTO = z.object({
  time_key: z.string().datetime({ offset: true }),
  params: BookingHistoryParam.array(),
});
export type BookingHistoryDetailsChronologicalChangesDTO = z.infer<
  typeof BookingHistoryDetailsChronologicalChangesDTO
>;
