import {
  GuestFormContainer,
  GuestHistory,
  GuestHistoryError,
  GuestInfo,
  GuestInfoError,
  GuestNotes,
  GuestNotesError,
  guestNotesLoader,
} from "components/GuestInfo";
import { AppLayout, RestaurantLayout } from "components/Layout";
import { loader as createBookingLoader } from "components/hall-scheme/redux/Booking/CreateBooking";
import { HallContainer } from "components/hall-scheme/redux/HallContainer";
import {
  CreateUserModal,
  DeleteUserModal,
  EditUserModal,
  Roles,
  RolesErrorBoundary,
  createUserAction,
  createUserLoader,
  deleteUserAction,
  editUserAction,
  rolesLoader,
  userInfoLoader,
} from "components/settings/sub-renders/Roles";
import { config } from "config";
import {
  Auth,
  loginAction,
  loader as loginLoader,
  logoutAction,
} from "containers/Auth";
import { BookingOrders, bookingOrdersLoader } from "containers/BookingOrders";
import {
  BookingDateStep,
  BookingDateStepErrorBoundary,
  CREATE_BOOKING_FORM_ROUTE_ID,
  ClientInfoStep,
  ClientInfoStepErrorBoundary,
  CreateBookingFormErrorBoundary,
  CreateBookingFormLayout,
  CreateBookingFormProvider,
  ExtraStepErrorBoundary,
  ExtrasStep,
  GuestAndTimeStepErrorBoundary,
  GuestAndTimeStepWrapper,
  TableStep,
  TableStepErrorBoundary,
  createBookingFormLoader,
} from "containers/CreateBookingForm";
import { Dashboard, dashboardLoader } from "containers/Dashboard";
import {
  BookingChat,
  BookingDetailsHeader,
  BookingHistory,
  BookingInfo,
  BookingSlotDetailsError,
  EmptyDetails,
  ManagementDetailsHeader,
  ManagementInfo,
  ManagementSlotDetailsError,
  bookingSlotLoader,
  managementSlotLoader,
} from "containers/Dashboard/SlotDetails";
import {
  Dialog,
  Dialogs,
  createBookingFromDialogLoader,
} from "containers/Dialogs";
import {
  BookingChatWebView,
  loaderBookingChatWebView,
} from "containers/Dialogs/BookingChatWebView/BookingChatWebView";
import { loader as dialogLoader } from "containers/Dialogs/Dialog/Dialog";
import { FeedList } from "containers/Dialogs/FeedList/FeedList";
import {
  EditRecordFromDialog,
  loader as editRecordLoader,
} from "containers/Dialogs/ReservationFromDialog/EditRecordFromDialog";
import { ReservationFromDialog } from "containers/Dialogs/ReservationFromDialog/ReservationFromDialog";
import {
  ClientCard,
  Clients,
  EditSource,
  EmptyClientDetails,
  EmptySourceDetails,
  SourceCard,
  SourceCreationCard,
  SourceInfo,
  Sources,
  createGuestLoader,
  guestsLoader,
} from "containers/Guests";
import { GuestsLayout } from "containers/Guests/GuestsLayout";
import { HallScheme } from "containers/HallScheme";
import { RequestsGrid } from "containers/RequestsGrid";
import {
  ScheduleLanding,
  scheduleLandingLoader,
} from "containers/ScheduleLanding";
import { Search } from "containers/Search";
import { Settings, settingsLoader } from "containers/Settings";
import { LayoutWebView } from "features/WebView";
import { WebViewProvider } from "features/WebView/provider";
import { createBrowserRouter, redirect } from "react-router-dom";

import { AppProvider } from "./providers/AppProvider";
import { RootProvider, rootLoader } from "./root";

export const router = createBrowserRouter([
  // ---------------------------------------------------------
  // WebViews: страницы для встраивания в нативное приложение
  {
    path: "/webviews",
    element: (
      <WebViewProvider mock={false} debug={false}>
        <RootProvider noAuth />
      </WebViewProvider>
    ),
    children: [
      {
        element: <AppProvider />,
        children: [
          {
            element: (
              <LayoutWebView
                getPageName={(pathname) => pathname.split("/")[2]}
              />
            ),
            children: [
              // Чат по заявке
              {
                path: "dialogs/bookings/:clientId/:bookingId",
                loader: loaderBookingChatWebView,
                element: <BookingChatWebView />,
              },
              // Раздел "Диалоги"
              {
                path: "dialogs",
                element: <Dialogs />,
                children: [
                  {
                    path: ":dialogId",
                    loader: dialogLoader,
                    element: <Dialog />,
                    children: [{ index: true, element: <FeedList /> }],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // ---------------------------------------------------------
  // Обычные страницы
  {
    path: "/",
    loader: rootLoader, //переместить ли?
    shouldRevalidate: ({ nextUrl, defaultShouldRevalidate }) =>
      defaultShouldRevalidate || nextUrl.pathname === "/", //ревалидация, если идем в компоненте ниже, но с url /,
    element: <RootProvider />,
    children: [
      {
        element: <AppProvider />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                element: <RestaurantLayout />,
                children: [
                  {
                    loader: dashboardLoader,
                    path: "/dashboard",
                    element: <Dashboard />,
                    children: [
                      { index: true, element: <EmptyDetails /> },
                      {
                        loader: bookingSlotLoader,
                        path: "booking/:slotId",
                        element: <BookingDetailsHeader />,
                        errorElement: <BookingSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <BookingInfo />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "history",
                            element: <BookingHistory />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "chat",
                            element: <BookingChat />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                        ],
                      },
                      {
                        loader: managementSlotLoader,
                        path: "management/:slotId",
                        element: <ManagementDetailsHeader />,
                        errorElement: <ManagementSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <ManagementInfo />,
                            errorElement: <ManagementSlotDetailsError />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/hall-scheme",
                    element: <HallScheme />,
                    children: [
                      {
                        loader: bookingSlotLoader,
                        path: "booking/:slotId",
                        element: <BookingDetailsHeader />,
                        errorElement: <BookingSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <BookingInfo />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "history",
                            element: <BookingHistory />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                          {
                            path: "chat",
                            element: <BookingChat />,
                            errorElement: <BookingSlotDetailsError />,
                          },
                        ],
                      },
                      {
                        loader: managementSlotLoader,
                        path: "management/:slotId",
                        element: <ManagementDetailsHeader />,
                        errorElement: <ManagementSlotDetailsError />,
                        children: [
                          { index: true, loader: () => redirect("info") },
                          {
                            path: "info",
                            element: <ManagementInfo />,
                            errorElement: <ManagementSlotDetailsError />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    loader: createBookingLoader,
                    path: "/create-booking",
                    element: <HallContainer isCreateBookingOpened />,
                  },
                  {
                    path: "/hall",
                    element: <HallContainer />,
                  },
                  {
                    loader: bookingOrdersLoader,
                    path: "/requests",
                    element: <BookingOrders />,
                  },
                  {
                    loader: guestsLoader,
                    path: "/guests",
                    element: <GuestsLayout />,
                    children: [
                      { index: true, loader: () => redirect("clients") },
                      {
                        path: "clients",
                        element: <Clients />,
                        children: [
                          {
                            index: true,
                            element: <EmptyClientDetails />,
                          },
                          {
                            loader: createGuestLoader,
                            path: "create",
                            element: <GuestFormContainer />,
                          },
                          {
                            path: ":clientId",
                            element: <ClientCard />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect("info"),
                              },
                              {
                                path: "info",
                                element: <GuestInfo />,
                                errorElement: <GuestInfoError />,
                              },
                              {
                                path: "history",
                                element: <GuestHistory />,
                                errorElement: <GuestHistoryError />,
                              },
                              {
                                loader: guestNotesLoader,
                                path: "notes",
                                element: <GuestNotes />,
                                errorElement: <GuestNotesError />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "sources",
                        element: <Sources />,
                        children: [
                          {
                            index: true,
                            element: <EmptySourceDetails />,
                          },
                          {
                            path: "create",
                            element: <SourceCreationCard />,
                          },
                          {
                            path: ":sourceId",
                            element: <SourceCard />,
                            children: [
                              {
                                index: true,
                                loader: () => redirect("info"),
                              },
                              {
                                path: "info",
                                element: <SourceInfo />,
                                errorElement: <GuestInfoError />,
                              },
                              {
                                path: "edit",
                                element: <EditSource />,
                                errorElement: <GuestInfoError />,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/grid",
                    loader: () =>
                      config.requestGrid ? null : redirect("/dashboard"),
                    element: <RequestsGrid />,
                  },
                  {
                    path: "/dialogs",
                    element: <Dialogs />,
                    children: [
                      {
                        path: ":dialogId",
                        loader: dialogLoader,
                        element: <Dialog />,
                        children: [
                          { index: true, element: <FeedList /> },
                          {
                            path: "create-booking",
                            loader: createBookingFromDialogLoader,
                            element: <ReservationFromDialog />,
                          },
                          {
                            path: "edit/:record",
                            loader: editRecordLoader,
                            element: <EditRecordFromDialog />,
                          },
                          { path: "*", loader: () => redirect("/dialogs") },
                        ],
                      },
                    ],
                  },
                  {
                    loader: scheduleLandingLoader,
                    path: "/schedule-landing",
                    element: <ScheduleLanding />,
                  },
                  {
                    loader: settingsLoader,
                    path: "/settings",
                    element: <Settings />,
                    children: [
                      {
                        path: "roles",
                        loader: rolesLoader,
                        element: <Roles />,
                        errorElement: <RolesErrorBoundary />,
                        children: [
                          {
                            loader: createUserLoader,
                            path: "create",
                            element: <CreateUserModal />,
                            action: createUserAction,
                          },
                          {
                            path: ":userUUID",
                            children: [
                              { index: true, loader: () => redirect("edit") },
                              {
                                path: "edit",
                                loader: userInfoLoader,
                                element: <EditUserModal />,
                                action: editUserAction,
                              },
                              {
                                path: "delete",
                                loader: userInfoLoader,
                                element: <DeleteUserModal />,
                                action: deleteUserAction,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/search",
                    element: <Search />,
                  },
                ],
              },
              {
                loader: createBookingFormLoader,
                path: "/create-booking-new",
                id: CREATE_BOOKING_FORM_ROUTE_ID,
                element: (
                  <CreateBookingFormProvider>
                    <CreateBookingFormLayout />
                  </CreateBookingFormProvider>
                ),
                errorElement: <CreateBookingFormErrorBoundary />,
                children: [
                  { loader: () => redirect("step-1"), index: true },
                  {
                    path: "step-1",
                    element: <BookingDateStep />,
                    errorElement: <BookingDateStepErrorBoundary />,
                  },
                  {
                    path: "step-2",
                    element: <GuestAndTimeStepWrapper />,
                    errorElement: <GuestAndTimeStepErrorBoundary />,
                  },
                  {
                    path: "step-3",
                    element: <ClientInfoStep />,
                    errorElement: <ClientInfoStepErrorBoundary />,
                  },
                  {
                    path: "step-4",
                    element: <TableStep />,
                    errorElement: <TableStepErrorBoundary />,
                  },
                  {
                    path: "step-5",
                    element: <ExtrasStep />,
                    errorElement: <ExtraStepErrorBoundary />,
                  },
                  { path: "*", loader: () => redirect("/create-booking-new") },
                ],
              },
            ],
          },
        ],
      },
      {
        loader: loginLoader,
        path: "/login",
        element: <Auth />,
        action: loginAction,
      },
      {
        loader: logoutAction,
        path: "/logout",
        action: logoutAction,
      },
      { path: "*", loader: () => redirect("/dashboard") },
    ],
  },
]);
