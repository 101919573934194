import type { RefObject } from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";
import { invariant } from "utils";

type ModalDialogContext = {
  dialogRef: RefObject<HTMLDialogElement>;
  formId?: string;
  openDialog: () => void;
  closeDialog: (returnValue?: string) => void;
  onCancel?: () => void;
};

const modalDialogContext = createContext<ModalDialogContext>(null!);

const useModalDialogContext = () => {
  const value = useContext(modalDialogContext);
  return (value ||
    invariant(
      value,
      "ModalDialogContext not passed, please wrap your components with <ModalDialog />",
    )) as ModalDialogContext;
};

const useModalDialogContextSelector = <Selected>(
  selector: (context: ModalDialogContext) => Selected,
) =>
  useContextSelector<ModalDialogContext, Selected>(
    modalDialogContext,
    selector,
  );

export {
  modalDialogContext,
  useModalDialogContext,
  useModalDialogContextSelector,
};
