import { getPersonsSuffix } from "containers/CreateBookingForm/utils";
import { useCallback, useEffect, useState } from "react";
import { type Control, useController } from "react-hook-form";
import { useIntl } from "react-intl";
import { SelectBasic } from "ui-kit";

import type { GuestAndTimeFormSchemaInput } from "../model/schema";
import styles from "./GuestCountCustomSelect.module.scss";

export const GuestCountSelect = ({
  name,
  min,
  control,
}: {
  name: "persons";
  min: number;
  control: Control<GuestAndTimeFormSchemaInput>;
}) => {
  const { formatMessage } = useIntl();

  const { field } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: String(min),
  });

  const createOptions = useCallback(
    (length: number) =>
      Array.from({ length }, (_, k) => ({
        label: `${k + min} ${formatMessage({
          id: getPersonsSuffix(k + min),
        }).toLowerCase()}`,
        value: k + min,
      })),
    [min],
  );
  const defaultState = createOptions(24);

  useEffect(() => {
    Number(field.value) < min && field.onChange(String(min));
  }, []);

  const [options, setOptions] = useState(defaultState);
  return (
    <SelectBasic
      className={styles.guestCountSelect}
      options={options}
      value={{
        label: `${field.value} ${formatMessage({
          id: getPersonsSuffix(Number(field.value)),
        }).toLowerCase()}`,
        value: field.value,
      }}
      onChange={(option) =>
        option && "value" in option && field.onChange(String(option.value))
      }
      onBlur={field.onBlur}
      onMenuScrollToBottom={() =>
        setOptions((prev) => createOptions(prev.length + 12))
      }
      onMenuScrollToTop={() => setOptions(defaultState)}
      captureMenuScroll
    />
  );
};
